import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { NavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import MapIcon from "@material-ui/icons/Map";
import { Grid, Button } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import "./styles.scss";
import CloseIcon from "@material-ui/icons/Close";
import FooterComponent from "../../components/FooterComponent";
import { generalService } from "../../_services";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const DashboardPage = () => {
  const [statistic, setStatistic] = useState({});
  const [isFooterOpen, setFooterOpen] = useState(false);
  const [is_map_visible, setMapVisible] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await generalService.getAdminStatistic();
      setStatistic(res);
    })();
  }, []);

  const toggleView = () => {
    setMapVisible(!is_map_visible);
  };

  const toggleFooter = () => {
    setFooterOpen(!isFooterOpen);
  };

  const renderUserWidget = () => {
    const loginUserCounts = statistic.loginUserCounts || [];
    const labels = [0, 1, 2, 3, 4, 5, 6].map((idx) =>
      moment(statistic.from?.split("T")[0]).add(idx, "day").format("DD MMM")
    );
    const values = labels.map((date) => {
      const c = loginUserCounts.find(
        (c) => moment(c.date_trunc.split("T")[0]).format("DD MMM") === date
      );
      return c ? +c.count : 0;
    });
    let usersIn24 = values[6];
    let usersInWeek = loginUserCounts.reduce((sum, cur) => sum + +cur.count, 0);

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: "top",
          onClick: () => {},
        },
        title: {
          display: false,
          text: "",
        },
      },
    };
    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: "Daily Active Users",
          data: values,
          borderColor: "#ea5545",
          backgroundColor: "#ea554588",
        },
      ],
    };

    return (
      <div className="overview-widget-block-1">
        <Grid container className="value-block">
          <Grid item md={6} xs={12} className="txt-value widget-block-2">
            <p className="block-title">All registered users</p>
            <h2>{statistic.totalUserCount || 0}</h2>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            order={{ xs: 3, md: 2 }}
            className="txt-value widget-block-2"
          >
            <p className="block-title">Active users over the past 24 hours</p>
            <h2>{usersIn24}</h2>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            order={{ xs: 2, md: 3 }}
            className="txt-value widget-block-2"
          >
            <p className="block-title">Verified users</p>
            <h2>{statistic.totalVerifiedUserCount || 0}</h2>
          </Grid>
          <Grid item md={6} xs={12} className="txt-value widget-block-2">
            <p className="block-title">Active users over the past week</p>
            <h2>{usersInWeek}</h2>
          </Grid>
        </Grid>
        {statistic.from && (
          <div className="chart-container">
            <Line options={options} data={data} />
          </div>
        )}
      </div>
    );
  };

  const renderTransactionWidget = () => {
    const transactionCounts = statistic.transactionCounts || [];
    const labels = [0, 1, 2, 3, 4, 5, 6].map((idx) =>
      moment(statistic.from?.split("T")[0]).add(idx, "day").format("DD MMM")
    );
    const values = labels.map((date) => {
      const c = transactionCounts.find(
        (c) => moment(c.date_trunc.split("T")[0]).format("DD MMM") === date
      );
      return c ? +c.count : 0;
    });

    let transactionIn24 = values[6];
    let transactionInWeek = transactionCounts.reduce(
      (sum, cur) => sum + +cur.count,
      0
    );

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: "top",
          onClick: () => {},
        },
        title: {
          display: false,
          text: "",
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: "Daily Transactions",
          data: values,
          borderColor: "#27aeef",
          backgroundColor: "#27aeef88",
        },
      ],
    };

    return (
      <div className="overview-widget-block-1">
        <Grid container className="value-block">
          <Grid item md={12} xs={12} className="txt-value widget-block-2">
            <p className="block-title">Number of user who hold token</p>
            <h2>{statistic.walletUserCounts || 0}</h2>
          </Grid>
          <Grid item md={6} xs={12} className="txt-value widget-block-2">
            <p className="block-title">Transactions over the past 24 hours</p>
            <h2>{transactionIn24}</h2>
          </Grid>
          <Grid item md={6} xs={12} className="txt-value widget-block-2">
            <p className="block-title">Transactions over the past week</p>
            <h2>{transactionInWeek}</h2>
          </Grid>
        </Grid>
        <div className="chart-container">
          <Line options={options} data={data} />
        </div>
      </div>
    );
  };

  const renderPropertyrWidget = () => {
    const labels = [0, 1, 2, 3, 4, 5, 6].map((idx) =>
      moment(statistic.from?.split("T")[0]).add(idx, "day").format("DD MMM")
    );

    const propertyCounts = statistic.propertyCounts || [];
    const propertyValue = labels.map((date) => {
      const c = propertyCounts.find(
        (c) => moment(c.date_trunc.split("T")[0]).format("DD MMM") === date
      );
      return c ? +c.count : 0;
    });
    // const propertyIn24 = values[6];
    // const propertyInWeek = propertyCounts.reduce((sum, cur) => sum + (+cur.count), 0);

    const soldCounts = statistic.soldCounts || [];
    const soldValue = labels.map((date) => {
      const c = soldCounts.find(
        (c) => moment(c.date_trunc.split("T")[0]).format("DD MMM") === date
      );
      return c ? +c.count : 0;
    });
    // const soldIn24 = values[6];
    // const soldInWeek = soldCounts.reduce((sum, cur) => sum + (+cur.count), 0);

    const investmentCounts = statistic.investmentCounts || [];
    const investmentValue = labels.map((date) => {
      const c = investmentCounts.find(
        (c) => moment(c.date_trunc.split("T")[0]).format("DD MMM") === date
      );
      return c ? +c.count : 0;
    });
    // const investIn24 = values[6];
    // const investInWeek = investmentCounts.reduce((sum, cur) => sum + (+cur.count), 0);

    const comittedInvestmentCounts = statistic.comittedInvestmentCounts || [];
    const comittedValue = labels.map((date) => {
      const c = comittedInvestmentCounts.find(
        (c) => moment(c.date_trunc.split("T")[0]).format("DD MMM") === date
      );
      return c ? +c.count : 0;
    });
    // const comittedIn24 = values[6];
    // const comittedInWeek = comittedInvestmentCounts.reduce((sum, cur) => sum + (+cur.count), 0);

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: "",
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          fill: false,
          label: "New Properties",
          data: propertyValue,
          borderColor: "#f46a9b",
          backgroundColor: "#f46a9b00",
        },
        {
          fill: false,
          label: "Properties Sold",
          data: soldValue,
          borderColor: "#87bc45",
          backgroundColor: "#87bc4500",
        },
        {
          fill: false,
          label: "Property Investment Transaction",
          data: investmentValue,
          borderColor: "#00bfa0",
          backgroundColor: "#00bfa000",
        },
        {
          fill: false,
          label: "Funding Commitment",
          data: comittedValue,
          borderColor: "#b33dc6",
          backgroundColor: "#b33dc600",
        },
      ],
    };

    return (
      <div className="overview-widget-block-1">
        <Grid container className="value-block">
          <Grid item md={6} xs={12} className="txt-value widget-block-2">
            <p className="block-title">Total Number of Properties</p>
            <h2>{statistic.totalPropertyCount || 0}</h2>
          </Grid>
          <Grid item md={6} xs={12} className="txt-value widget-block-2">
            <p className="block-title">Total Number of Properties Sold</p>
            <h2>{statistic.totalSoldCount || 0}</h2>
          </Grid>
          <Grid item md={6} xs={12} className="txt-value widget-block-2">
            <p className="block-title">
              Total Number of Properties Investment Transactions
            </p>
            <h2>{statistic.totalInvestmentCount || 0}</h2>
          </Grid>
          <Grid item md={6} xs={12} className="txt-value widget-block-2">
            <p className="block-title">Total Number of Funding Comitements</p>
            <h2>{statistic.totalCommitedInvestmentCount || 0}</h2>
          </Grid>
        </Grid>
        <div className="chart-container">
          <Line options={options} data={data} />
        </div>
      </div>
    );
  };

  return (
    <div className="admin-dashboard-content">
      <NavBarComponent pageName="admin-dashboard" />

      <main className="main-content">
        <Grid container>
          <Grid item xs={12} className="new-grid-box">
            <h2 className="block-title">Users</h2>
            {renderUserWidget()}
          </Grid>
          <Grid item xs={12} className="new-grid-box">
            <h2 className="block-title">Transactions</h2>
            {renderTransactionWidget()}
          </Grid>
          <Grid item xs={12} className="new-grid-box">
            <h2 className="block-title">Properties</h2>
            {renderPropertyrWidget()}
          </Grid>
        </Grid>

        <Button
          variant="contained"
          className="btn-toggle-view"
          onClick={toggleView}
        >
          {!is_map_visible ? (
            <span>
              Map <MapIcon />
            </span>
          ) : (
            <span>
              List <ListIcon />
            </span>
          )}
        </Button>
        {isFooterOpen ? (
          <Button
            variant="contained"
            className="btn-footer-action"
            onClick={toggleFooter}
          >
            <CloseIcon /> Close
          </Button>
        ) : (
          <Button
            variant="contained"
            className="btn-footer-action"
            onClick={toggleFooter}
          >
            GTC, Privacy policy & more
          </Button>
        )}
      </main>
      {isFooterOpen ? (
        <div className="footer-block">
          <FooterComponent />
        </div>
      ) : null}
    </div>
  );
};

function mapState(state) {
  const { users } = state;
  return { users };
}

const actionCreators = {};

export default connect(mapState, actionCreators)(DashboardPage);
