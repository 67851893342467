import React, { useEffect, useState } from "react";
import { NavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { Button, Grid, Card } from "@material-ui/core";
import { useConfirm } from "../../components/Confirm";
import seedWords from "../../utils/seedwords";
import { TagBox } from "react-tag-box";
import "./tokenwalletpage.scss";
import {
  seedUtils,
  addressBalance,
  addressAssetBalance,
} from "@0bsnetwork/zbs-transactions";
import { WalletSeedBackupModal } from "../../components/WalletSeedBackupModal";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../_actions";
import { authHeader } from "../../_helpers";
import { secureStorage } from "../../_services";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopy";
import NotificationBox from "../../components/GeneralComponent/NotificationBox";

const ZBS_DECIMAL = 100000000;
const IMMO_DECIMAL = 100;
const seedWordsObj = seedWords.map((it) => ({ value: it, label: it }));

function TokenWalletPage({
  users,
  alert,
  updateUserProfile,
  clearAlerts,
  claimTokens,
}) {
  const { isConfirmed } = useConfirm();
  const [accountCreationStep, setAccountCreationStep] = useState(0);
  const [hasAccount, setHasAccount] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [seed, setSeed] = useState("");
  const [address, setAddress] = useState("");
  const [seedModalOpen, setSeedModalOpen] = useState(false);
  const [immoBalance, setImmoBalance] = useState(0);
  const [zbsBalance, setZbsBalance] = useState(0);

  // wallet
  const userClaimedTokens = JSON.parse(secureStorage.getItem("tokens_claimed"));

  var balInterval;

  useEffect(() => {
    if (checkIfAccount()) {
      let encryptionKey = process.env.REACT_APP_SEED_ENCRYPTION + "###"; //TODO: Salt
      let encryptedSeed = secureStorage.getItem("encryptedSeed");
      if (encryptedSeed) {
        let seedDecrypted = seedUtils.decryptSeed(
          encryptedSeed,
          encryptionKey,
          2048
        );
        if (seedDecrypted !== "") {
          setSeed(seedDecrypted);
          let seedObj = seedUtils.Seed.fromExistingPhrase(
            seedDecrypted,
            process.env.REACT_APP_CHAIN_ID
          );
          setAddress(seedObj.address);

          balInterval = setInterval(() => checkBalance(seedObj.address), 30000);
          checkBalance(seedObj.address);
        }

        setHasAccount(true);
      }
    } else if (secureStorage.getItem("wallet_address")) {
      const wallet_address = secureStorage.getItem("wallet_address");
      setAddress(wallet_address);

      balInterval = setInterval(() => checkBalance(wallet_address), 30000);
      checkBalance(wallet_address);

      setHasAccount(true);
    }

    return () => {
      clearInterval(balInterval);
    };
  }, []);

  const checkIfAccount = () => {
    return secureStorage.getItem("encryptedSeed");
  };

  const generateSeed = () => {
    let newSeed = seedUtils.Seed.create(15, process.env.REACT_APP_CHAIN_ID);
    const seedWordsObjGen = newSeed.phrase
      .split(" ")
      .map((it) => ({ value: it, label: it }));
    setSelectedTags(seedWordsObjGen);
  };

  const selectTag = (tag) => {
    const newTag = {
      label: tag.label.trim(),
      value: tag.value ? tag.value.trim() : tag.label.trim(),
    };
    let sel = selectedTags;
    sel.push(newTag);
    setSelectedTags(sel);
  };

  const removeTag = (tag) => {
    let sel = selectedTags;
    sel = sel.filter((item) => item.value !== tag.value);
    setSelectedTags(sel);
  };

  const createAccount = () => {
    generateSeed();
    setAccountCreationStep(1);
  };

  const importAccount = () => {
    setSelectedTags([]);
    setAccountCreationStep(1);
  };

  const checkBalance = async (walletAddress) => {
    let nodeAddress = process.env.REACT_APP_NODE_API;
    const bal = await addressBalance(walletAddress, nodeAddress);
    const assetBal = await addressAssetBalance(
      walletAddress,
      process.env.REACT_APP_TOKEN_ID,
      nodeAddress
    );
    setZbsBalance(bal / ZBS_DECIMAL);
    setImmoBalance(assetBal / IMMO_DECIMAL);
  };

  const saveAccount = async () => {
    let encryptionKey = process.env.REACT_APP_SEED_ENCRYPTION + "###"; // TODO: Salt

    let seedPlain = selectedTags.map((u) => u.label).join(" ");
    let seedObj = seedUtils.Seed.fromExistingPhrase(
      seedPlain,
      process.env.REACT_APP_CHAIN_ID
    );
    let seedEncrypted = seedObj.encrypt(encryptionKey, 2048);

    if (users.profile_data.wallet_address !== seedObj.address) {
      const willUpdate = await isConfirmed({
        title: "Connect to your wallet",
        message:
          "Your wallet does not match the wallet that you already setup. Would you like to update your setting with new wallet?",
        okay: "Yes",
        cancel: "No",
        disableBackdropClick: true,
      });

      if (!willUpdate) {
        return;
      }
    }

    secureStorage.setItem("encryptedSeed", seedEncrypted);
    secureStorage.setItem("wallet_address", seedObj.address);
    setSeed(seedObj.phrase);
    setAddress(seedObj.address);
    setHasAccount(true);
    checkBalance(seedObj.address);

    updateUserProfile({
      update_form_type: "wallet_address",
      wallet_address: seedObj.address,
    }).then((value) => {
      setTimeout(() => clearAlerts(), 1000);
    });
  };

  const copyAddress = (addr) => {
    navigator.clipboard.writeText(addr);
  };

  const unlinkAccount = async () => {
    secureStorage.removeItem("encryptedSeed");
    secureStorage.removeItem("wallet_address");
    setHasAccount(false);
    setAddress("");
    setZbsBalance(0);
    setImmoBalance(0);
    setSeed("");
    setSelectedTags([]);

    const form_data = new FormData();
    form_data.append("wallet_address", "");
    form_data.append("update_form_type", "general_information");

    const requestOptions = {
      method: "PUT",
      headers: { ...authHeader() },
      body: form_data,
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/users/profile`,
      requestOptions
    );
    const data = await res.json();
  };

  const userClaimTokens = () => {
    const wallet_address = secureStorage.getItem("wallet_address");
    claimTokens({ wallet_address });
    secureStorage.setItem("tokens_claimed", true);
  };

  return (
    <div className="dashboard-content">
      {alert.message && (
        <NotificationBox
          open={true}
          variant={alert.type}
          message={alert.message}
        />
      )}
      <NavBarComponent pageName="token-wallet" />

      <main className="main-content token-wallet-page">
        <Grid className="new-grid-box">

        
        {/* // Step 0 */}
        {!hasAccount && accountCreationStep == 0 && (
          <>
          
            <h2 className="page-block-title">Create Blockchain Account</h2>

            <p className="page-block-title">
              If you already have a seed phrase, you can import it, otherwise
              create a new account and a seed will be generated for you
            </p>
          

            <Grid container spacing={3} style={{ marginTop: 10 }} >
              <Grid item md={12} xs={12}>
                <Button className="btn-secondary" onClick={createAccount}>
                  Create Account
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                <Button className="btn-secondary" onClick={importAccount}>
                  Import Account
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {/* // Step 1 */}
        {!hasAccount && accountCreationStep == 1 && (
          <>
            <h2 className="page-block-title">Seed Phrase</h2>

            <p className="page-block-title">
              Confirm your seed phrase below, and ensure that it is written down
              in multiple safe places
            </p>

            <Grid container spacing={3} style={{ marginTop: 10 }} >
              <Grid item md={7} xs={7}>
                <TagBox
                  tags={seedWordsObj}
                  selected={selectedTags}
                  onSelect={selectTag}
                  removeTag={removeTag}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Button className="btn-secondary" onClick={saveAccount}>
                  Save Seed Phrase
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {hasAccount && (
          <>
            
            <h2 className="page-block-title">Token Wallet</h2>

            <p className="page-block-title">
              See your token holdings below, backup your account, and send
              tokens to others
            </p>
            

            <Grid item md={12} xs={12} style={{ marginTop: 30 }} >
              <div className="block-title">Details</div>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="overview-widget-block-1">
                    <div className="value-block">
                      <div
                        className="txt-value widget-block-2"
                        style={{ padding: 5 }}
                      >
                        <span style={{ width: "30%" }}>Address</span>
                        <p style={{ fontSize: 16, display: "inline" }}>
                          &nbsp;{address}&nbsp;
                        </p>
                        <p style={{ display: "inline" }}>
                          <IconButton
                            style={{ paddingLeft: 2 }}
                            onClick={() => copyAddress(address)}
                          >
                            <CopyIcon />
                          </IconButton>
                        </p>
                      </div>
                    </div>
                    <div className="value-block">
                      <div
                        className="txt-value widget-block-3"
                        style={{ padding: 5 }}
                      >
                        <span style={{ width: "30%", display: "inline-block" }}>
                          Account Details
                        </span>
                        {seed ? (
                          <span
                            style={{ width: "50%", display: "inline-block" }}
                          >
                            <Button
                              className="btn-secondary"
                              variant="outlined"
                              onClick={() => setSeedModalOpen(true)}
                            >
                              Backup
                            </Button>
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} style={{ marginTop: 30 }} >
              <div className="block-title">Balances</div>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="overview-widget-block-1">
                    {!userClaimedTokens ? (
                      <div className="value-block">
                        <Button
                          className="btn-secondary"
                          variant="outlined"
                          onClick={() => userClaimTokens()}
                        >
                          Claim Welcome Tokens
                        </Button>
                      </div>
                    ) : null}
                    <div className="value-block">
                      <p
                        className="txt-value widget-block-2"
                        style={{ padding: 5 }}
                      >
                        <span>Immobilium</span>
                        <span>{immoBalance}</span>
                      </p>
                    </div>
                    <div className="value-block">
                      <p
                        className="txt-value widget-block-2"
                        style={{ padding: 5 }}
                      >
                        <span>ZBS</span>
                        <span>{zbsBalance}</span>
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} style={{ marginTop: 30 }} >
              <div className="block-title">Account Functions</div>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="overview-widget-block-1">
                    <div className="value-block">
                      <div
                        className="txt-value widget-block-3"
                        style={{ padding: 5 }}
                      >
                        <Button
                          className="btn-error"
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            textTransform: "none",
                          }}
                          onClick={unlinkAccount}
                        >
                          Unlink Account
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        </Grid>
      </main>

      {seedModalOpen && (
        <WalletSeedBackupModal
          handleClose={() => setSeedModalOpen(false)}
          open={seedModalOpen}
          seed={seed}
        />
      )}
    </div>
  );
}

function mapState(state) {
  const { users, alert } = state;
  return { users, alert };
}

const actionCreators = {
  updateUserProfile: userActions.updateUserProfile,
  claimTokens: userActions.claimTokensRequest,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(TokenWalletPage);
