// src/components/ManageUsersTable.jsx
import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"; // for "Send Tokens"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"; // deposit
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"; // withdraw
import { Link } from "react-router-dom";

export default function ManageUsersTable({
  users,
  page,
  limit,
  count,
  handlePageChange,
  handleRowsPerPageChange,
  openTokensModal,
  openDepositModal,
  openWithdrawModal,
  handleDelete,
}) {
  // For Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuUser, setMenuUser] = useState(null);

  const handleMenuOpen = (event, user) => {
    setAnchorEl(event.currentTarget);
    setMenuUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuUser(null);
  };

  return (
    <TableContainer className="custom-table">
      <Table>
        {/* TABLE HEAD */}
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Is Verified</TableCell>
            <TableCell>Is Admin</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        {/* TABLE BODY */}
        <TableBody>
          {users.length === 0 && (
            <TableRow>
              <TableCell colSpan={8}  style={{ padding: "50px 0" }}>
                No users
              </TableCell>
            </TableRow>
          )}
          {users.map((user, idx) => (
            <TableRow key={user.user_id}>
              <TableCell>{page * limit + idx + 1}</TableCell>
              <TableCell>{user.first_name}</TableCell>
              <TableCell>{user.last_name}</TableCell>
              <TableCell>{user.email_id}</TableCell>
              <TableCell >
                {user.is_verified ? "Yes" : "No"}
              </TableCell>
              <TableCell >
                {user.is_admin ? "Admin" : ""}
              </TableCell>
              <TableCell >
                {moment(user.createdAt).format("MMMM DD, YYYY hh:mm")}
              </TableCell>
              <TableCell >
                <IconButton
                  onClick={(event) => handleMenuOpen(event, user)}
                  aria-controls="users-table-menu"
                  aria-haspopup="true"
                  size="small"
                >
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        {/* TABLE FOOTER WITH PAGINATION */}
        <TableFooter>
          <TableRow>
            <TablePagination
              className="custom-pagination"
              rowsPerPageOptions={[10, 50]}
              labelRowsPerPage="Items per page:"
              page={page}
              count={count}
              rowsPerPage={limit}
              onRowsPerPageChange={handleRowsPerPageChange}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>

      {/* MENU for user actions */}
      <Menu
        id="users-table-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuUser && (
          <>
            {/* EDIT */}
            <MenuItem
              component={Link}
              to={`/dashboard/users/${menuUser.user_id}`}
              onClick={handleMenuClose}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>

            {/* SEND TOKENS */}
            <MenuItem
              onClick={() => {
                handleMenuClose();
                openTokensModal(menuUser);
              }}
              disabled={!menuUser.wallet_address} // disable if no wallet
            >
              <ListItemIcon>
                <AccountBalanceWalletIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  menuUser.wallet_address ? "Send Tokens" : "No Wallet"
                }
              />
            </MenuItem>

            {/* DEPOSIT */}
            <MenuItem
              onClick={() => {
                handleMenuClose();
                openDepositModal(menuUser.user_id);
              }}
            >
              <ListItemIcon>
                <ArrowUpwardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Deposit" />
            </MenuItem>

            {/* WITHDRAW */}
            <MenuItem
              onClick={() => {
                handleMenuClose();
                openWithdrawModal(menuUser.user_id);
              }}
            >
              <ListItemIcon>
                <ArrowDownwardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Withdraw" />
            </MenuItem>

            {/* DELETE */}
            <MenuItem
              onClick={() => {
                handleMenuClose();
                handleDelete(menuUser.user_id);
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
          </>
        )}
      </Menu>
    </TableContainer>
  );
}
