import React from "react";
import { useNavigate } from "react-router-dom";

const PartnersSection = ({ property }) => {
  const navigate = useNavigate();
  const partners = property.property_project_partners;
  // console.log(partners);
  if (partners.length) {
    return (
      <div className="partners-section">
        <div className="partners-wrapper">
          <h3>Partners</h3>
          <div className="partners-list">
            {partners.map((partner) => (
              <div key={partner.id} className="partner">
                <img
                  height="60px"
                  src={partner.partner_master.logo}
                  style={{ marginBottom: "16px" }}
                />
                <h4>
                  {partner.partner_master.name}{" "}
                  <span>
                    [
                    <a
                      onClick={() =>
                        navigate(
                          `/marketplace?partner_id=${partner.partner_master.id}`
                        )
                      }
                    >
                      explore all
                    </a>
                    ]
                  </span>
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: partner.partner_master.description,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default PartnersSection;
