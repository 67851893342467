// src/components/ManagePartnerTable.jsx

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import "../AdminDashboard/styles.scss"

export default function ManagePartnerTable({
  partners,
  page,
  limit,
  count,
  handleRowsPerPageChange,
  handlePageChange,
  handlePartnerRemove,
}) {
  return (
    <TableContainer className="custom-table">
      <Table>
        {/* TABLE HEAD */}
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Logo</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        {/* TABLE BODY */}
        <TableBody>
          {partners.length > 0 ? (
            partners.map((partner, index) => (
              <TableRow key={partner.id}>
                <TableCell >{page * limit + index + 1}</TableCell>
                <TableCell style={{minWidth: '200px'}}>{partner.name}</TableCell>
                <TableCell style={{maxWidth: '200px'}}>
                  <img
                    style={{maxWidth: '150px'}}
                    alt="partner-logo"
                    src={partner.logo}
                    className="partner-logo"
                  />
                </TableCell>
                <TableCell >
                  {ReactHtmlParser(partner.description)}
                </TableCell>
                <TableCell >
                  <div style={{display: 'flex', gap: '5px'}}>
                    <IconButton
                      // variant="outlined"
                      // size="small"
                      // color="primary"
                      startIcon={<EditIcon />}
                      component={Link}
                      to={`/dashboard/partners/edit/${partner.id}`}
                    >
                      <EditIcon style={{color: "#2baaea"}} />
                    </IconButton>
                    <IconButton
                      // variant="outlined"
                      // size="small"
                      
                      onClick={() => handlePartnerRemove(partner.id)}
                    >
                      <DeleteIcon style={{color: '#dc004e'}} />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}  style={{ padding: "50px 0" }}>
                No partners found
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        {/* TABLE FOOTER WITH PAGINATION */}
        <TableFooter>
          <TableRow>
            <TablePagination
              className="custom-pagination"
              rowsPerPageOptions={[10, 50]}
              labelRowsPerPage="Items per page:"
              page={page}
              count={count}
              rowsPerPage={limit}
              onRowsPerPageChange={handleRowsPerPageChange}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
