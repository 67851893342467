import React from "react";
import {
  Grid,
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Paper,
  Avatar,
} from "@material-ui/core";
import { Close, DragHandle, CloudUpload } from "@material-ui/icons";
import { TabPanel } from "../../../../components/GeneralComponent/LanguageTabs";
import "../../styles.scss";

import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";

const RenderPicturePart = (
  state,
  handleFloorPlanImageCapture,
  handleRemoveFloorPlanPicture,
  handlePropertyPictureCapture,
  onSortEnd,
  handlePropertyPictureTabValue,
  handlePropertyPictureDescChange,
  handleRemovePropertyPicture,
  ref
) => {
  const { property } = state;

  return (
    <div className="step-wizard-block block-3" id="step_3">
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <Box component="div" m={2} ml={0}>
            <div>
              <h3>Floor Plan</h3>
            </div>
            <Button startIcon={<CloudUpload />} variant="outlined" color="default" disableElevation component="label">
              <span>Upload Floor Plan</span>
              <input
                type="file"
                id="floor_plan_images"
                onChange={handleFloorPlanImageCapture}
                className="floor-plan-image-file"
                accept="image/*"
                multiple="multiple"
              />
            </Button>
          </Box>
          <Box component="div" m={2} ml={0}>
            {property.property_floor_plans
              .filter((f) => !f.is_delete)
              .map((floor_plan, indx) => (
                <Box
                  key={indx}
                  className="floor_plan_box"
                  component="div"
                  m={2}
                  ml={0}
                  display="inline-block"
                  position="relative"
                >
                  <Avatar
                    className="floor-plan-image"
                    alt="Floor plan Image"
                    variant="square"
                    src={floor_plan.floor_plan_image_preview}
                  />
                  <Button
                    onClick={handleRemoveFloorPlanPicture(floor_plan)}
                    className="action-button"
                    variant="outlined"
                    size="small"
                    color="secondary"
                  >
                    <Close />
                  </Button>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <div>
            <h3>Property Pictures</h3>
          </div>
          <Box sx={{ mt: 1, mb: 2, color: "#949292" }}>
            Note: Image description will be used only for Search Engine
            Optimization (SEO)
          </Box>
          <Button startIcon={<CloudUpload />} variant="outlined" color="default" disableElevation component="label">
            <span>Upload Picture</span>
            <input
              type="file"
              id="property_logo"
              onChange={handlePropertyPictureCapture}
              className="upload-picture-file"
              accept="image/*"
              multiple="multiple"
              ref={ref}
            />
          </Button>
        </Grid>
        <Grid item md={12} xs={12}>
          <SortableList
            onSortEnd={onSortEnd}
            lockAxis="y"
            allowDrag={true}
            className="dragged-list"
            draggedItemClassName="dragged-element"
          >
            {property.property_picture.length > 0 && (
              <>
                {property.property_picture
                  .filter((p) => !p.is_delete)
                  .sort((a, b) => a.index - b.index)
                  .map((picture, indx) => {
                    return (
                      <SortableItem
                        key={"image-" + indx}
                        draggedItemClassName="dragged-element"
                      >
                        <Paper className="property-picture-item-container">
                          <Grid container spacing={1}>
                            <Grid item md={3} sm={12} xs={12}>
                              <Avatar
                                className="picture"
                                alt="Profile Picture"
                                variant="square"
                                src={picture.property_picture_preview}
                              />
                            </Grid>
                            <Grid item md={8} sm={10} xs={12}>
                              <TabPanel value={picture.tab_value} index={0}>
                                <TextField
                                  fullWidth
                                  multiline
                                  minRows={3}
                                  value={picture.image_description_en}
                                  onChange={handlePropertyPictureDescChange(
                                    indx
                                  )}
                                  name="image_description_en"
                                  className="form-control"
                                  label="Image Description"
                                  variant="outlined"
                                />
                              </TabPanel>
                            </Grid>
                            <Grid item md={1} sm={2} xs={12}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 10,
                                }}
                              >
                                <Button
                                  onClick={handleRemovePropertyPicture(picture)}
                                  className="action-button"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                >
                                  <Close />
                                </Button>
                                <SortableKnob>
                                  <Button
                                    title={"Click and move to reorder pictures"}
                                  >
                                    <DragHandle />
                                  </Button>
                                </SortableKnob>
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>
                      </SortableItem>
                    );
                  })}
              </>
            )}
          </SortableList>
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderPicturePart;
