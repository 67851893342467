import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  IconButton,
} from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import { Add, Close } from "@material-ui/icons";
import { TabPanel } from "../../../../components/GeneralComponent/LanguageTabs";
import PropertyMapView from "../../PropertyMapview";
import PropertyDetailEdit from "../PropertyDetailEdit";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Editor from "../../../../components/Editor";
import DeleteIcon from "@material-ui/icons/Delete";
import "../../styles.scss";

const RenderDetailPart = (
  state,
  handleChangePropertyType,
  onUpdateProperty,
  onUpdatePropertyLocation,
  mapComponentRef,
  handleAddPropertyLocation,
  handleAddKeyFigureRow,
  handleKeyFigureChange,
  handleRemoveKeyFigureRow,
  handleSectionsChange,
  handleAddPartnerRow,
  handlePartnerChange,
  handleRemovePartnerRow,
  handleEditorChange
) => {
  const {
    property,
    legal_warnings_tabIndex,
    forecasted_distribution_tabIndex,
    available_partners_list,
  } = state;
  return (
    <div className="step-wizard-block block-1" id="step_1">
      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <Grid item md={4} xs={12} style={{ marginBottom: "16px" }}>
            <FormControl fullWidth>
              {/* <InputLabel shrink>Property Category</InputLabel> */}
              <Select
                id="text-property_type"
                name="property_type"
                value={property.property_type}
                onChange={handleChangePropertyType}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="direct">
                  <em>Direct Sale</em>
                </MenuItem>
                <MenuItem disabled>
                  <em>Other Transaction Types Coming Soon</em>
                </MenuItem>
                {/* <MenuItem value="secondary">
                  <em>Sale</em>
                </MenuItem>
                <MenuItem value="primary">
                  <em>Crowdfunding</em>
                </MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          {state.loaded && (
            <PropertyDetailEdit
              property={property}
              onUpdate={onUpdateProperty}
              onUpdateLocation={onUpdatePropertyLocation}
            />
          )}
          {/* <Card className="default-card">
            <CardHeader
              title={
                property.property_type === "direct"
                  ? "Key Features"
                  : "Key Figures"
              }
            />
            <CardContent>
              <table className="table table-striped table-bordered">

                <tbody>
                  {property.key_figures.length > 0 && (
                    <>
                      {property.key_figures.map((key_figure_item, index) =>
                        key_figure_item.isDelete ? (
                          ""
                        ) : (
                          <tr key={index}>
                            <td align="center">{index + 1}</td>
                            <td align="center">
                              <TabPanel
                                value={key_figure_item.tab_value_key}
                                index={0}
                              >
                                <TextValidator
                                  fullWidth
                                  value={key_figure_item.property_key_en}
                                  onChange={handleKeyFigureChange(index)}
                                  name="property_key_en"
                                  className="form-control"
                                  label="Key"
                                  disabled={index > 3 ? false : true}
                                />
                              </TabPanel>
                            </td>
                            <td align="center">
                              <TabPanel
                                value={key_figure_item.tab_value_val}
                                index={0}
                              >
                                <TextValidator
                                  fullWidth
                                  value={key_figure_item.property_value_en}
                                  onChange={handleKeyFigureChange(index)}
                                  name="property_value_en"
                                  className="form-control"
                                  label="Value"
                                  validators={["required", "isFloat"]}
                                  errorMessages={[
                                    "This field is required",
                                    "This field must be a decimal number",
                                  ]}
                                />
                              </TabPanel>
                            </td>
                            <td align="center">
                              {["Sq Feet", "Sq Meters"].includes(
                                key_figure_item.property_key_en
                              ) ? (
                                <Tooltip
                                  title={
                                    <React.Fragment>
                                      You can switch measurement unit in your
                                      profile settings. Click here to be taken
                                      to your profile.
                                    </React.Fragment>
                                  }
                                  placement="top"
                                  onClick={() =>
                                    (window.location = "/dashboard/profile")
                                  }
                                  arrow
                                >
                                  <InfoOutlinedIcon
                                    style={{
                                      fontSize: "25px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              {index > 3 ? (
                                <Button
                                  onClick={handleRemoveKeyFigureRow(index)}
                                  className="action-button"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                >
                                  <Close />
                                </Button>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </CardContent>
          </Card> */}
          <h3>
            {property.property_type === "direct"
              ? "Key Features"
              : "Key Figures"}
          </h3>
          <Grid container spacing={3}>
            {property.key_figures.map((key_figure_item, index) => {
              return (
                <Grid item xs={12} sm={6} md={3} key={key_figure_item.id}>
                  <TextValidator
                    fullWidth
                    value={key_figure_item.property_value_en}
                    onChange={handleKeyFigureChange(index)}
                    name="property_value_en"
                    className="form-control"
                    label={key_figure_item.property_key_en}
                    validators={["required", "isFloat"]}
                    errorMessages={[
                      "This field is required",
                      "This field must be a decimal number",
                    ]}
                    variant="outlined"
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h3>Description</h3>
              {property.sections.length > 0 && (
                <>
                  {property.sections.map((section, index) => {
                    return section.section_heading_en === "Highlights" ||
                      (section.section_heading_en === "The Property" &&
                        section.section_description_en) ? (
                      <div>
                        <Editor
                          name={"section_description_en"}
                          id={"text-section_description_en_" + index}
                          value={section.section_description_en}
                          onChange={handleSectionsChange}
                          plugins="autolink link image lists preview"
                          toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                          index={index}
                        />
                        {state[section.section_key + "_error"] !== "" && (
                          <p className="editor-error">
                            {state[section.section_key + "_error"]}
                          </p>
                        )}
                      </div>
                    ) : null;
                  })}
                </>
              )}
              {/* <Editor
              label="Description *"
              name={"section_description_en"}
              id={
                "text-section_description_en_0"
              }
              value={section.section_description_en}
              onChange={handleSectionsChange}
              plugins="autolink link image lists preview"
              toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
              index={0}
            />
            {state[section.section_key + "_error"] !==
              "" && (
              <p className="editor-error">
                {state[section.section_key + "_error"]}
              </p>
            )} */}
            </Grid>
          </Grid>
        </Grid>
        {/* {console.log(property.key_figures)} */}
        <Grid item md={4} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <h3>Property Location</h3>
              <p>Select place by dragging location marker</p>
              {/* <Card className="default-card"> */}
              {/* <CardHeader
                  title="Property Location"
                  subheader="&#9736; Select place by dragging location marker"
                /> */}
              {/* <CardContent> */}
              <PropertyMapView
                ref={mapComponentRef}
                handleAddPropertyLocation={handleAddPropertyLocation}
              />
              {/* </CardContent> */}
              {/* </Card> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container spacing={1}>
            {/* <Grid item md={12} xs={12}>
              <Card className="default-card">
                <CardHeader title="Sections" />
                <CardContent>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Heading</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {property.sections.length > 0 && (
                        <>
                          {property.sections.map((section, index) => {
                            return section.section_heading_en ===
                              "Highlights" ||
                              (section.section_heading_en === "The Property" &&
                                section.section_description_en) ? (
                              <tr key={index}>
                                <td align="center">{index + 1}</td>
                                <td align="center">
                                  <TabPanel
                                    value={section.heading_tab_value}
                                    index={0}
                                  >
                                    <TextValidator
                                      fullWidth
                                      value={section.section_heading_en}
                                      onChange={handleSectionsChange}
                                      name="section_heading_en"
                                      className="form-control"
                                      label="Heading *"
                                      validators={["required"]}
                                      errorMessages={["This field is required"]}
                                    />
                                  </TabPanel>
                                </td>
                                {console.log('section', section)}
                                <td align="center">
                                  <TabPanel
                                    value={section.description_tab_value}
                                    index={0}
                                  >
                                    <Editor
                                      label="Description *"
                                      name={"section_description_en"}
                                      id={
                                        "text-section_description_en_" + index
                                      }
                                      value={section.section_description_en}
                                      onChange={handleSectionsChange}
                                      plugins="autolink link image lists preview"
                                      toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                                      index={index}
                                    />
                                    {state[section.section_key + "_error"] !==
                                      "" && (
                                      <p className="editor-error">
                                        {state[section.section_key + "_error"]}
                                      </p>
                                    )}
                                  </TabPanel>
                                </td>
                              </tr>
                            ) : null;
                          })}
                        </>
                      )}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            </Grid> */}
            <Grid container xs={8}>
              <h3 style={{ display: "block", width: "100%" }}>Partners</h3>
              <Grid item xs={12}>
                <Button
                  style={{ maxWidth: "calc(100% - 75px)" }}
                  variant="outlined"
                  size="large"
                  color="default"
                  onClick={handleAddPartnerRow}
                  disableElevation
                  // className="action-button"
                >
                  <Add />
                  Add new Partner
                </Button>
              </Grid>
              {/* <Grid item xs={12}> */}

              {property.partners.map(
                (partner, index) =>
                  !partner.isDelete && (
                    <Grid xs={12} item key={`partner ${index}`}>
                      <Box
                        style={{
                          display: "flex",
                          gap: "16px",
                          marginTop: "16px",
                        }}
                      >
                        <Select
                          name="partner_id"
                          value={partner.partner_id}
                          onChange={handlePartnerChange(index)}
                          variant="outlined"
                          fullWidth
                          // label="Select Partner"
                        >
                          <MenuItem value={0}>
                            <em>Select Partner</em>
                          </MenuItem>
                          {available_partners_list.length > 0 &&
                            available_partners_list
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((p, index) => (
                                <MenuItem key={index} value={p.id}>
                                  {p.name}
                                </MenuItem>
                              ))}
                        </Select>
                        <IconButton
                          onClick={handleRemovePartnerRow(index)}
                          aria-label="delete"
                        >
                          <DeleteIcon
                            style={{ color: "#dc004e" }}
                            fontSize="large"
                          />
                        </IconButton>
                      </Box>
                    </Grid>
                  )
              )}
              {/* </Grid> */}
            </Grid>

            {property.property_type !== "direct" && (
              <Grid item md={12} xs={12}>
                <p className="forecasted-distribution-lable">
                  Forecasted Distribution
                </p>
                <TabPanel value={forecasted_distribution_tabIndex} index={0}>
                  <Editor
                    id="text-forecasted_distribution_en"
                    label="Forecasted Distribution *"
                    name="forecasted_distribution_en"
                    value={property.forecasted_distribution_en}
                    onChange={handleEditorChange}
                    plugins="autolink link image lists preview"
                    toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                    height={200}
                  />
                </TabPanel>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <h3>
                {property.property_type === "direct"
                  ? "Legal Information"
                  : "Legal Warnings"}
              </h3>
              <TabPanel value={legal_warnings_tabIndex} index={0}>
                <Editor
                  id="text-legal_warnings_en"
                  label="Legal Warnings *"
                  key={state.loaded ? "key1" : "key2"}
                  name="legal_warnings_en"
                  value={property.legal_warnings_en}
                  onChange={handleEditorChange}
                  plugins="autolink link image lists preview"
                  toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                  height={200}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderDetailPart;
