import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Button,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PersonIcon from "@material-ui/icons/Person";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import NotificationBox from "../../../components/GeneralComponent/NotificationBox";
import { userActions, alertActions } from "../../../_actions";
import PhoneValidator from "../../../components/PhoneValidatorElement";
import SelectValidator from "../../../components/SelectValidatorElement";
import CountryData from "../../../_helpers/country-nationality.json";
import "./styles.scss";

class GeneralInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        user_id: 0,
        first_name: "",
        address: "",
        email_id: "",
        last_name: "",
        postal_code: "",
        contact_no: "",
        birth_date: "",
        city: "",
        phone: "",
        nationality: "",
        country: "",
        profile_image: "",
        profile_preview: "",
        update_form_type: "general_information",
        iban_number: "",
      },
    };
  }

  componentDidMount() {
    this.props.clearAlerts();

    ValidatorForm.addValidationRule("isPhoneNumber", (value) => {
      if (value.length < 5) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPhoneNumber");
  }

  handleChange = (event) => {
    if (!event.target) return;
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    if (fieldName === "nationality") {
      var countryDetail = CountryData.find((c) => c.nationality === fieldValue);
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          nationality: countryDetail.nationality,
          country: countryDetail.en_short_name,
        },
      }));
    } else {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          [fieldName]: fieldValue,
        },
      }));
    }
  };

  handleBirthDateChange = (date) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        birth_date: date,
      },
    }));
  };

  handleProfileUpload = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          profile_image: file,
          profile_preview: reader.result,
        },
      }));
    };
    reader.readAsDataURL(file);
  };

  updateUserLogo = () => {
    document.getElementById("user_profile").click();
  };

  handleProfileUpdate = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.props.updateUserProfile(this.state.user).then((value) => {
          this.props.handleFetchUserProfile();
          setTimeout(() => this.props.clearAlerts(), 1000);
        });
      }
    });
  };

  handleUpdateProfile = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.props.updateProfile(this.state.user).then((value) => {
          this.props.handleFetchUserProfile();
          setTimeout(() => this.props.clearAlerts(), 1000);
        });
      }
    });
  };

  submit = () => {
    this.form.submit();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { profile_data } = nextProps.users;
    if (profile_data && profile_data.user_id !== prevState.user.user_id) {
      return {
        user: {
          user_id: profile_data.user_id,
          profile_preview: profile_data.profile_image,
          profile_image: "",
          first_name: profile_data.first_name,
          email_id: profile_data.email_id,
          last_name: profile_data.last_name,
          address: profile_data.address,
          postal_code: profile_data.postal_code,
          contact_no: profile_data.contact_no,
          birth_date: Date.parse(profile_data.birth_date),
          city: profile_data.city,
          phone: profile_data.phone,
          nationality: profile_data.nationality,
          country: profile_data.country,
          update_form_type: "general_information",
          iban_number: profile_data.iban_number,
        },
      };
    }
    return null;
  }

  render() {
    const { alert } = this.props;
    const { user } = this.state;

    return (
      <div className="general-information-tab">
        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}

        <p className="page-block-title">General information</p>

        <div className="user-picture-block">
          <div className="user-avtar">
            {user.profile_preview ? (
              <img
                onClick={this.updateUserLogo}
                alt="user-profile"
                className="user-avtar"
                src={user.profile_preview}
              />
            ) : (
              <div className="user-avtar" onClick={this.updateUserLogo}>
                <PersonIcon color="error" className="profile-icon" />
              </div>
            )}
            <Button
              className="btn-upload"
              variant="contained"
              component="label"
            >
              <CloudUploadIcon />
              <input
                type="file"
                id="user_profile"
                onChange={this.handleProfileUpload}
                accept="image/*"
                style={{ display: "none", opacity: 0 }}
              />
            </Button>
          </div>
          <div className="user-name">
            {user.first_name + " " + user.last_name}
          </div>
        </div>
        <div className="user-profile-form">
          <ValidatorForm
            className="default-form"
            onSubmit={this.handleProfileUpdate}
            ref={(r) => {
              this.form = r;
            }}
            autoComplete="off"
            instantValidate
          >
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <label className="form-label">First name *</label>
                <TextValidator
                  placeholder="Enter First name"
                  name="first_name"
                  id="first_name"
                  value={user.first_name}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  className="form-control"
                  validators={["required"]}
                  errorMessages={["First name is required!"]}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <label className="form-label">Address</label>
                <TextValidator
                  placeholder="Enter Address"
                  name="address"
                  value={user.address}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  className="form-control"
                  // validators={["required"]}
                  // errorMessages={["Address is required!"]}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <label className="form-label">Email Address *</label>
                <TextValidator
                  placeholder="Enter Email Address"
                  name="email_id"
                  value={user.email_id}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                  className="form-control"
                  validators={["required"]}
                  errorMessages={["Email is required!"]}
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <label className="form-label">Last name *</label>
                <TextValidator
                  placeholder="Enter Last name"
                  name="last_name"
                  value={user.last_name}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  className="form-control"
                  validators={["required"]}
                  errorMessages={["Last name is required!"]}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <label className="form-label">Postal Code</label>
                <TextValidator
                  placeholder="Enter Postal Code"
                  name="postal_code"
                  onChange={this.handleChange}
                  value={user.postal_code}
                  variant="outlined"
                  fullWidth
                  className="form-control"
                  // validators={["required"]}
                  // errorMessages={["Postal code is required!"]}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <label className="form-label">Mobile phone number</label>
                <PhoneValidator
                  name="contact_no"
                  onChange={this.handleChange}
                  value={user.contact_no}
                  phoneNumber={user.contact_no}
                  country="at"
                  // validators={["isPhoneNumber"]}
                  // errorMessages={["Phone number is required!"]}
                />
              </Grid>

              <Grid item md={4} xs={12} className="bithdate-container">
                <label className="form-label">Birthdate</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="date-picker-birthdate"
                  isClearable={true}
                  placeholderText="Enter Birth date"
                  selectsStart={true}
                  shouldCloseOnSelect={true}
                  disabledKeyboardNavigation={false}
                  showYearDropdown={true}
                  customInput={
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      className="form-control"
                    />
                  }
                  forceShowMonthNavigation={true}
                  selected={user.birth_date}
                  onChange={this.handleBirthDateChange}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <label className="form-label">City</label>
                <TextValidator
                  placeholder="Enter city"
                  name="city"
                  onChange={this.handleChange}
                  value={user.city}
                  variant="outlined"
                  fullWidth
                  className="form-control"
                  // validators={["required"]}
                  // errorMessages={["City is required!"]}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <label className="form-label">Phone</label>
                <TextField
                  placeholder="Enter phone number"
                  name="phone"
                  onChange={this.handleChange}
                  value={user.phone}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <label className="form-label">Nationality</label>
                <FormControl variant="outlined" fullWidth>
                  <SelectValidator
                    name="nationality"
                    value={user.nationality}
                    onChange={this.handleChange}
                    fullWidth
                    displayEmpty
                    className="form-control"
                    // validators={["required"]}
                    // errorMessages={["Nationality is required!"]}
                  >
                    <MenuItem value="" disabled>
                      Select Nationality
                    </MenuItem>
                    {CountryData.map((country, index) => {
                      return (
                        <MenuItem key={index} value={country.nationality}>
                          {country.nationality}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <label className="form-label">Country</label>
                <FormControl variant="outlined" fullWidth>
                  <SelectValidator
                    name="country"
                    value={user.country}
                    onChange={this.handleChange}
                    fullWidth
                    displayEmpty
                    className="form-control"
                    // validators={["required"]}
                    // errorMessages={["Country is required!"]}
                  >
                    <MenuItem value="" disabled>
                      Select Country
                    </MenuItem>
                    {CountryData.map((country, index) => {
                      return (
                        <MenuItem key={index} value={country.en_short_name}>
                          {country.en_short_name}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <label className="form-label">IBAN</label>
                <TextValidator
                  placeholder="Enter IBAN"
                  name="iban_number"
                  onChange={this.handleChange}
                  value={user.iban_number}
                  variant="outlined"
                  fullWidth
                  className="form-control"
                  // validators={["required"]}
                  // errorMessages={["IBAN is required!"]}
                />
              </Grid>
            </Grid>

            <div className="form-action">
              <Button
                disableElevation
                // className="btn btn-primary btn-filled-out"
                onClick={this.submit}
                variant={"contained"}
                color="primary"
              >
                Update Settings
              </Button>
              <Button 
                variant="outlined"
                color="primary"
                disableElevation
              >
                Cancel
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { users, alert } = state;
  return { users, alert };
}

const actionCreators = {
  updateUserProfile: userActions.updateUserProfile,
  updateProfile: userActions.updateProfile,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(GeneralInformation);
