import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Box,
  TablePagination,
  Input,
} from "@material-ui/core";
import { alertActions, userActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { useConfirm } from "../../components/Confirm";
import DepositModal from "../../components/DashboardComponent/modals/DepositModal";
import WithdrawModal from "../../components/DashboardComponent/modals/WithdrawModal";
import DepositInfoAdminModal from "../../components/DashboardComponent/modals/DepositInfoAdminModal";
import WithdrawInfoAdminModal from "../../components/DashboardComponent/modals/WithdrawInfoAdminModal";
import { userManageService } from "../../_services";
import "./styles.scss";
import TokensModal from "../../components/DashboardComponent/modals/TokensModal";
import ManageUsersTable from "./ManageUsersTable";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const ManageUserPage = ({
  alert,
  clearAlerts,
  successAlerts,
  errorAlerts,
  depositMoney,
  withdrawMoney,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isConfirmed } = useConfirm();

  // Helper to parse URL params
  const parseURLParams = () => {
    const sp = new URLSearchParams(location.search);
    const parsedPage = parseInt(sp.get("page"), 10) || 0;
    const parsedLimit = parseInt(sp.get("limit"), 10) || 10;
    const parsedSearch = sp.get("search") || "";
    return { parsedPage, parsedLimit, parsedSearch };
  };

  const { parsedPage, parsedLimit, parsedSearch } = parseURLParams();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(parsedPage);
  const [limit, setLimit] = useState(parsedLimit);
  const [count, setCount] = useState(0);

  const [search, setSearch] = useState(parsedSearch);
  const [searchText, setSearchText] = useState(parsedSearch);

  // Debounce search input
  useEffect(() => {
    const delay = 250; // ms
    const handler = setTimeout(() => {
      const trimmed = searchText.trim();
      if (trimmed !== search) {
        setSearch(trimmed);
        setPage(0); // Reset to first page when search changes
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText, search]);

  const inititalUser = {
    id: 0,
    wallet_address: "",
    first_name: "",
    last_name: "",
  };
  const [selectedUser, setSelectedUser] = useState(inititalUser);
  const [deposit, setDeposit] = useState({
    amount: "0",
    modalStatus: false,
    confirmModalStatus: false,
  });
  const [tokens, setTokens] = useState({
    modalStatus: false,
  });
  const [withdraw, setWithdraw] = useState({
    amount: "0",
    modalStatus: false,
    confirmModalStatus: false,
  });

  // Update the URL if the current state doesn't match
  const updateURL = useCallback(() => {
    const params = new URLSearchParams();
    if (page > 0) params.set("page", page);
    if (limit !== 10) params.set("limit", limit);
    if (search) params.set("search", search);

    const paramString = params.toString();
    const currentURL =
      window.location.pathname + (paramString ? `?${paramString}` : "");

    // Only navigate if the URL differs from the current location
    if (currentURL !== window.location.pathname + window.location.search) {
      navigate(currentURL, { replace: true });
    }
  }, [page, limit, search, navigate]);

  const loadUsers = useCallback(() => {
    clearAlerts();
    userManageService
      .getUsers(page, limit, search)
      .then(({ error, data, pagination }) => {
        if (!error && data) {
          setUsers(data);
          if (pagination && typeof pagination.count !== "undefined") {
            setCount(pagination.count);
          } else {
            setCount(0);
          }
        } else {
          setUsers([]);
          setCount(0);
        }
      });
  }, [page, limit, search]);

  // Handle back/forward navigation:
  useEffect(() => {
    const {
      parsedPage: newPage,
      parsedLimit: newLimit,
      parsedSearch: newSearch,
    } = parseURLParams();

    let changed = false;
    if (newPage !== page) {
      setPage(newPage);
      changed = true;
    }
    if (newLimit !== limit) {
      setLimit(newLimit);
      changed = true;
    }
    if (newSearch !== search) {
      setSearchText(newSearch);
      setSearch(newSearch);
      changed = true;
    }
    // If nothing changed, do nothing. This prevents a loop.
  }, [location.search]);

  // Update URL whenever page, limit, or search changes
  useEffect(() => {
    updateURL();
  }, [page, limit, search, updateURL]);

  // Load users whenever page, limit, or search changes
  useEffect(() => {
    loadUsers();
  }, [page, limit, search, loadUsers]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setLimit(newSize);
    setPage(0);
  };

  const openDepositModal = (user_id) => {
    setDeposit((prev) => ({
      ...prev,
      modalStatus: true,
    }));
    setSelectedUser({ id: user_id });
  };

  const closeDepositModal = () => {
    setDeposit((prev) => ({
      ...prev,
      modalStatus: false,
    }));
  };

  const openWithdrawModal = (user_id) => {
    setWithdraw((prev) => ({
      ...prev,
      modalStatus: true,
    }));
    setSelectedUser({ id: user_id });
  };

  const closeWithdrawModal = () => {
    setWithdraw((prev) => ({
      ...prev,
      modalStatus: false,
    }));
  };

  const openTokensModal = (user) => {
    setTokens({
      modalStatus: true,
    });
    setSelectedUser({
      id: user.user_id,
      wallet_address: user.wallet_address,
      first_name: user.first_name,
      last_name: user.last_name,
    });
  };

  const closeTokensModal = () => {
    setTokens({
      modalStatus: false,
    });
    setSelectedUser(inititalUser);
  };

  const openConfirmModal = (action) => {
    const u = users.find((usr) => usr.user_id === selectedUser.id);
    if (u) {
      setSelectedUser((prev) => ({
        ...prev,
        first_name: u.first_name,
        last_name: u.last_name,
      }));
    }
    if (action === "deposit") {
      setDeposit((prev) => ({
        ...prev,
        confirmModalStatus: true,
      }));
    } else {
      setWithdraw((prev) => ({
        ...prev,
        confirmModalStatus: true,
      }));
    }
  };

  const closeConfirmModal = (action) => {
    if (action === "deposit") {
      setDeposit((prev) => ({
        ...prev,
        modalStatus: false,
        confirmModalStatus: false,
      }));
    } else {
      setWithdraw((prev) => ({
        ...prev,
        modalStatus: false,
        confirmModalStatus: false,
      }));
    }
  };

  const handleChange = (event) => {
    const amount = event.target.value;
    if (event.target.name === "deposit") {
      setDeposit((prev) => ({ ...prev, amount }));
    } else {
      setWithdraw((prev) => ({ ...prev, amount }));
    }
  };

  const nextDepositModal = () => {
    closeDepositModal();
    openConfirmModal("deposit");
  };

  const previousDepositModal = () => {
    setDeposit((prev) => ({
      ...prev,
      modalStatus: true,
      confirmModalStatus: false,
    }));
  };

  const nextWithdrawModal = () => {
    closeWithdrawModal();
    openConfirmModal("withdraw");
  };

  const previousWithdrawModal = () => {
    setWithdraw((prev) => ({
      ...prev,
      modalStatus: true,
      confirmModalStatus: false,
    }));
  };

  const handleDeposit = async () => {
    const user_id = selectedUser.id;
    await depositMoney(user_id, {
      deposit: deposit.amount,
    });
    closeConfirmModal("deposit");
    loadUsers();
  };

  const handleWithdraw = async () => {
    const user_id = selectedUser.id;
    await withdrawMoney(user_id, {
      withdraw: withdraw.amount,
    });
    closeConfirmModal("withdraw");
    loadUsers();
  };

  const handleDelete = async (user_id) => {
    clearAlerts();
    const confirmed = await isConfirmed({
      title: "Delete User?",
      message: "Are you sure you want to delete this user?",
      okay: "Yes",
      cancel: "No",
    });

    if (!confirmed) {
      return;
    }

    try {
      const { error, message } = await userManageService.deleteUser(user_id);
      if (error) {
        errorAlerts(message || "Something went wrong");
      } else {
        successAlerts("User updated successfully");
        loadUsers();
      }
    } catch (e) {
      console.log(e);
      errorAlerts("Something went wrong");
    }
  };

  return (
    <div className="main-content manage-user-page">
      <DasboardNavBarComponent pageName="manage-users" />

      

      {/* <Card>
        <CardContent className="table-scroll">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Is Verified</th>
                <th>Is Admin</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!users.length && (
                <tr>
                  <td colSpan={8} align="center" style={{ padding: "50px 0" }}>
                    No users
                  </td>
                </tr>
              )}
              {users.map((user, idx) => (
                <tr key={user.user_id}>
                  <td>{page * limit + idx + 1}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email_id}</td>
                  <td className="center">{user.is_verified ? "Yes" : "No"}</td>
                  <td className="center">{user.is_admin ? "Admin" : ""}</td>
                  <td className="center">
                    {moment(user.createdAt).format("MMMM DD, YYYY hh:mm")}
                  </td>
                  <td className="center">
                    <div className="action-buttons">
                      <Link to={`/dashboard/users/${user.user_id}`}>
                        <Button
                          className="edit"
                          variant="outlined"
                          size="small"
                          color="primary"
                          style={{ marginRight: 5 }}
                        >
                          Edit
                        </Button>
                      </Link>
                      <Button
                        className={user.wallet_address ? "promote" : "demote"}
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 5 }}
                        onClick={() => openTokensModal(user)}
                        disabled={!user.wallet_address}
                      >
                        {user.wallet_address ? "Send Tokens" : "No Wallet"}
                      </Button>
                      <Button
                        className="active"
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 5 }}
                        onClick={() => openDepositModal(user.user_id)}
                      >
                        Deposit
                      </Button>
                      <Button
                        className="sold"
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 5 }}
                        onClick={() => openWithdrawModal(user.user_id)}
                      >
                        Withdraw
                      </Button>
                      <Button
                        className="delete"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={() => handleDelete(user.user_id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <TablePagination
                  rowsPerPageOptions={[10, 50]}
                  labelRowsPerPage="Items per page:"
                  page={page}
                  count={count}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  onPageChange={handlePageChange}
                />
              </tr>
            </tbody>
          </table>
        </CardContent>
      </Card> */}

      <Card className="new-grid-box">
        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}

        <Box mb={3}>
          <Link to="/dashboard/users/add">
            <Button startIcon={<AddCircleOutlineIcon />} size="large" variant="outlined" color="default" disableElevation>
              Add User
            </Button>
          </Link>
        </Box>

        {/* Search Field */}
        <Box mb={3}>
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search by name or email..."
            style={{
              fontSize: "16px",
              padding: "6px 14px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              width: "250px",
              backgroundColor: "#fff",
            }}
            disableUnderline
          />
        </Box>
        <ManageUsersTable
          users={users}
          page={page}
          limit={limit}
          count={count}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          openTokensModal={openTokensModal}
          openDepositModal={openDepositModal}
          openWithdrawModal={openWithdrawModal}
          handleDelete={handleDelete}
        />
      </Card>
      <DepositModal
        handleClose={closeDepositModal}
        open={deposit.modalStatus}
        depositAmount={deposit.amount}
        handleChange={handleChange}
        nextDepositModal={nextDepositModal}
      />
      <DepositInfoAdminModal
        handleClose={() => closeConfirmModal("deposit")}
        open={deposit.confirmModalStatus}
        depositAmount={deposit.amount}
        user={selectedUser}
        handleBack={previousDepositModal}
        handleFinish={handleDeposit}
      />

      <WithdrawModal
        handleClose={closeWithdrawModal}
        open={withdraw.modalStatus}
        withdrawAmount={withdraw.amount}
        handleChange={handleChange}
        nextWithdrawModal={nextWithdrawModal}
      />
      <WithdrawInfoAdminModal
        handleClose={() => closeConfirmModal("withdraw")}
        open={withdraw.confirmModalStatus}
        withdrawAmount={withdraw.amount}
        user={selectedUser}
        handleBack={previousWithdrawModal}
        handleFinish={handleWithdraw}
      />
      <TokensModal
        handleClose={closeTokensModal}
        open={tokens.modalStatus}
        selectedUser={selectedUser}
      />
    </div>
  );
};

function mapState(state) {
  const { alert } = state;
  const { deposit_res } = state.users;
  return { alert, deposit_res };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  successAlerts: alertActions.success,
  errorAlerts: alertActions.error,
  depositMoney: userActions.depositMoney,
  withdrawMoney: userActions.withdrawMoney,
};

const connectedPage = connect(mapState, actionCreators)(ManageUserPage);

export { connectedPage as ManageUserPage };
export default connectedPage;
