import React, { useState } from 'react';
import {
  Box,
  Button,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TablePagination,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  makeStyles
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';


// const useStyles = makeStyles((theme) => ({
//   /* 
//     Styles the Paper inside TableContainer
//     to have the desired border radius and box shadow 
//   */
//   tablePaper: {
//     borderRadius: 16,
//     boxShadow: '2px 16px 19px 0px rgba(0, 0, 0, 0.09)',
//     overflow: 'hidden', // ensures rounded corners on the table
//   },
//   /* 
//     Applies background color to the entire TableHead 
//   */
//   tableHead: {
//     backgroundColor: '#F1F4F9',
//   },
// }));


function PropertyTable({
  properties,
  showPending,
  getPropertyRowClass,
  page,
  handleRowsPerPageChange,
  handlePageChange,
  secureStorage,
  openTransferModal,
  handlePropertyDemote,
  calculateRemainingPromotionDays,
  openPromoteModal,
  isNumber,
  immoTokenBalance,
  handlePropertySold,
  handlePropertyStatus,
  openDeleteModal,
}) {
  // State to control which row's menu is open
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuProperty, setMenuProperty] = useState(null);
  // const classes = useStyles();

  const handleMenuOpen = (event, property) => {
    setAnchorEl(event.currentTarget);
    setMenuProperty(property);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuProperty(null);
  };

  return (

      <TableContainer className="custom-table">
        <Table>
          {/* TABLE HEAD */}
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              {secureStorage.getItem("is_admin") && (
                <TableCell>Uploaded by</TableCell>
              )}
              <TableCell>Payment Ref No.</TableCell>
              <TableCell>Address Line1</TableCell>
              <TableCell>Address City</TableCell>
              <TableCell>Address Postcode</TableCell>
              <TableCell>Address Country</TableCell>
              {/* Instead of an Action column with multiple buttons, 
                  we’ll just display the menu icon here. */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          {/* TABLE BODY */}
          <TableBody>
            {properties.map((property, index) => {
              const { first_name: firstName, last_name: lastName } =
                property.user_property_master;

              // Skip properties that are not pending if showPending is true
              if (showPending && !property.status.includes("pending")) {
                return null;
              }

              return (
                <TableRow
                  key={property.property_id || index}
                  className={getPropertyRowClass(
                    property.status,
                    property.is_draft
                  )}
                >
                  <TableCell>{page.page * page.size + index + 1}</TableCell>
                  <TableCell>
                    {property.title}
                    {property.is_draft && <span> [DRAFT]</span>}
                  </TableCell>
                  {secureStorage.getItem("is_admin") && (
                    <TableCell>{`${firstName} ${lastName}`}</TableCell>
                  )}
                  <TableCell>{property.purchase_ref_number}</TableCell>
                  <TableCell>{property.address_line1}</TableCell>
                  <TableCell>{property.address_city}</TableCell>
                  <TableCell>{property.address_postcode}</TableCell>
                  <TableCell>{property.address_country}</TableCell>

                  {/* ACTIONS (IconButton to open Menu) */}
                  <TableCell>
                    <IconButton
                      // ref={anchorRef}
                      onClick={(event) => handleMenuOpen(event, property)}
                      aria-controls="action-menu"
                      aria-haspopup="true"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          {/* TABLE FOOTER with PAGINATION */}
          <TableFooter>
            <TableRow>
            <TablePagination
              className="custom-pagination"
              rowsPerPageOptions={[10, 50]}
              labelRowsPerPage="Items per page:"
              page={page.page}
              count={page.count}
              rowsPerPage={page.size}
              onRowsPerPageChange={handleRowsPerPageChange}
              onPageChange={handlePageChange}
            />
            </TableRow>
          </TableFooter>
        </Table>
        

        {/* MENU - shown when user clicks on MoreVertIcon in a row */}
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          // variant="select"
          // disableScrollLock={true}
          // Position the menu’s anchor
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          // Position how the menu “transforms” from that anchor
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'center',
          // }}
        >
          {menuProperty && menuProperty.status.includes("pending") ? (
            // If property is 'pending' and user is admin -> "Check request"
            secureStorage.getItem("is_admin") && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  openTransferModal(menuProperty);
                }}
              >
                Check request
              </MenuItem>
            )
          ) : (
            // Otherwise display the usual actions
            <div>
              {/* EDIT BUTTON */}
              {menuProperty && !menuProperty.external ? (
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    // Navigate to Edit page
                  }}
                  component={Link}
                  to={
                    menuProperty
                      ? `/dashboard/properties/edit/${menuProperty.property_id}`
                      : "#"
                  }
                >
                  Edit
                </MenuItem>
              ) : (
                <MenuItem disabled>Edit</MenuItem>
              )}

              {/* PROMOTE / DEMOTE */}
              {menuProperty && menuProperty.is_promoted ? (
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handlePropertyDemote(menuProperty.property_id);
                  }}
                >
                  Demote{" "}
                  <Tooltip
                    title={`Promoted for ${calculateRemainingPromotionDays(
                      menuProperty.promoted_date
                    )} more days`}
                    placement="top"
                  >
                    <InfoIcon fontSize="small" style={{ marginLeft: 6 }} />
                  </Tooltip>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    openPromoteModal(menuProperty.property_id);
                  }}
                  disabled={!isNumber(immoTokenBalance)}
                >
                  Promote
                </MenuItem>
              )}

              {/* SOLD / SET SOLD */}
              {menuProperty && (
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handlePropertySold(menuProperty.property_id);
                  }}
                >
                  {menuProperty.is_sold ? "Sold" : "Set Sold"}
                </MenuItem>
              )}

              {/* ACTIVATE / DEACTIVATE */}
              {menuProperty && (
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handlePropertyStatus(menuProperty.property_id);
                  }}
                >
                  {menuProperty.status === "active" ? "Deactivate" : "Activate"}
                </MenuItem>
              )}

              {/* DELETE */}
              {menuProperty && (
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    openDeleteModal(menuProperty.property_id);
                  }}
                >
                  Delete
                </MenuItem>
              )}
            </div>
          )}
        </Menu>
      </TableContainer>
  );
}

export default PropertyTable;
