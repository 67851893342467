import React, { useState } from "react";
import BedroomIcon from "../../assets/images/bed-room.svg";
import BathroomsIcon from "../../assets/images/bathroom.svg";
import ParkingIcon from "../../assets/images/parking.svg";
import SquareFootIcon from "@material-ui/icons/SquareFoot";
import {
  camelCaseToTitleCase,
  findSectionByKey,
} from "../../components/helpers/helpers";
import FeaturedPlayListOutlinedIcon from "@material-ui/icons/FeaturedPlayListOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { Box, Link, Typography } from "@material-ui/core";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const WhatPlaceOffersSection = ({ property }) => {
  const [openSlidesGallery, setOpenSlidesGallery] = useState(false);
  const kyeFigures = property.property_key_figures;
  // console.log(property?.property_floor_plans);
  const slides =
    property?.property_floor_plans.length > 0
      ? property?.property_floor_plans.map((property_image) => {
          return {
            src: property_image.image_path,
          };
        })
      : [];
  const getIcon = (key) => {
    switch (key) {
      case "Bedrooms":
        return <img src={BedroomIcon} />;
      case "Bathrooms":
        return <img src={BathroomsIcon} />;
      case "Parking Spaces":
        return <img src={ParkingIcon} />;
      case "Sq Feet":
        return <SquareFootIcon />;
      case "Search":
        return <SearchOutlinedIcon />;
      default:
        return <FeaturedPlayListOutlinedIcon />;
    }
  };

  if (kyeFigures) {
    return (
      <div className="what-place-offers-section">
        <div className="what-place-offers-wrapper">
          <h3>What this place offers</h3>
          <div className="key-figures-row">
            <div className="kye-figures-list">
              {kyeFigures.map((keyFigure) => (
                <div key={keyFigure.id} className="kye-figure">
                  {getIcon(keyFigure.property_key)}{" "}
                  {camelCaseToTitleCase(keyFigure.property_key)}{" "}
                  <span className="key-figure-value">
                    {keyFigure.property_value === "true"
                      ? "Yes"
                      : keyFigure.property_value}
                  </span>
                </div>
              ))}
            </div>
            <div>
              {property.property_floor_plans?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    // alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  {[property.property_floor_plans[0]].map((fp, idx) => (
                    <img
                      src={fp.image_path}
                      alt="floor-plan-img"
                      className="floor-plan-img"
                      onClick={() => setOpenSlidesGallery(true)}
                      key={idx}
                    />
                  ))}
                  <Link
                    onClick={() => setOpenSlidesGallery(true)}
                    style={{
                      color: "#000",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {getIcon("Search")}
                    <span>
                      View floor plans{" "}
                      <Typography
                        style={{ display: "inline", color: "#2BAAEA" }}
                      >
                        ({property.property_floor_plans.length})
                      </Typography>
                    </span>
                  </Link>
                </Box>
              )}
            </div>
          </div>
        </div>
        <Lightbox
          open={openSlidesGallery}
          close={() => setOpenSlidesGallery(false)}
          slides={slides}
        />
      </div>
    );
  }
  return null;
};

export default WhatPlaceOffersSection;
