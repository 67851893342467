import { authHeader } from "../_helpers";
import { userService } from "./index";

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (response.status === 451) {
      window.location = "/users/kyc";
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function getUsers(pageNo = 0, pageSize = 10, search) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users?page=${pageNo}&limit=${pageSize}&search=${search}`,
    requestOptions
  ).then(handleResponse);
}

function getUser(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

function addUser(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(user),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users`, requestOptions).then(
    handleResponse
  );
}

function updateUser(id, user) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(user),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

function deleteUser(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

function setUserToAdmin(id) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}/admin`,
    requestOptions
  ).then(handleResponse);
}

function removeUserFromAdmin(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}/admin`,
    requestOptions
  ).then(handleResponse);
}

export const userManageService = {
  getUsers,
  getUser,
  addUser,
  updateUser,
  setUserToAdmin,
  removeUserFromAdmin,
  deleteUser,
};
