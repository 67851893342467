import React from "react";
import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import SpeedOutlinedIcon from "@material-ui/icons/SpeedOutlined";
import AdminDashboardIcon from "@material-ui/icons/Dashboard";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MamageUsersIcon from "@material-ui/icons/SupervisedUserCircle";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import "../styles.scss";
import { secureStorage } from "../../../_services";
import HeaderLogo from "./HeaderLogo";

export default function NavBarLinks(props) {
  const { navControl, page } = props;
  const is_admin = secureStorage.getItem("is_admin");

  function getActiveMenu(pageName) {
    return pageName === page ? "nav-link active" : "nav-link";
  }

  return (
    <List disablePadding className="nav-link-bar new-admin-dashboard">
      <ListItem className="logo-nav-item">
        <HeaderLogo users={props.users} />
      </ListItem>
      <ListItem
        className={getActiveMenu("dashboard")}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <SpeedOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard" className="nav-text">
            Dashboard
          </NavLink>
        </ListItemText>
      </ListItem>
      {is_admin && (
        <ListItem
          className={getActiveMenu("admin-dashboard")}
          button
          onClick={navControl}
        >
          <ListItemIcon className="nav-icon">
            <AdminDashboardIcon />
          </ListItemIcon>
          <ListItemText>
            <NavLink to="/dashboard/admin" className="nav-text">
              Admin Dashboard
            </NavLink>
          </ListItemText>
        </ListItem>
      )}
      <ListItem
        className={getActiveMenu("manage-property")}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <HomeWorkIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/properties" className="nav-text">
            Manage Properties
          </NavLink>
        </ListItemText>
      </ListItem>
      <ListItem
        className={getActiveMenu("payments")}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <PaymentOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/payments" className="nav-text">
            Payments
          </NavLink>
        </ListItemText>
      </ListItem>
      {is_admin && (
        <>
          <ListItem
            className={getActiveMenu("manage-partner")}
            button
            onClick={navControl}
          >
            <ListItemIcon className="nav-icon">
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/dashboard/partners" className="nav-text">
                Manage Partners
              </NavLink>
            </ListItemText>
          </ListItem>

          <ListItem
            className={getActiveMenu("manage-location")}
            button
            onClick={navControl}
          >
            <ListItemIcon className="nav-icon">
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/dashboard/locations" className="nav-text">
                Manage Locations
              </NavLink>
            </ListItemText>
          </ListItem>

          <ListItem
            className={getActiveMenu("manage-users")}
            button
            onClick={navControl}
          >
            <ListItemIcon className="nav-icon">
              <MamageUsersIcon />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/dashboard/users" className="nav-text">
                Manage Users
              </NavLink>
            </ListItemText>
          </ListItem>
        </>
      )}
      <ListItem
        className={getActiveMenu("token-wallet")}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <AccountBalanceWalletIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/tokenwallet" className="nav-text">
            Token Wallet
          </NavLink>
        </ListItemText>
      </ListItem>
      <ListItem
        className={getActiveMenu("profile")}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <FaceOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink
            to="/dashboard/profile"
            onClick={navControl}
            className="nav-text"
          >
            Profile
          </NavLink>
        </ListItemText>
      </ListItem>
    </List>
  );
}
