import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  Divider,
  TablePagination,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Input,
  Tooltip,
  TextField,
  Grid,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { parseISO, addDays, differenceInDays } from "date-fns";
import { connect } from "react-redux";
import { generalActions, alertActions } from "../../_actions";
import NotificationBox from "../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import DeleteModal from "../../components/DeleteModal";
import TransferPropertyModal from "../../components/DashboardComponent/modals/TransferPropertyModal";
import TokenPayModal from "../../components/DashboardComponent/modals/TokenPayModal";
import {
  generalService,
  secureStorage,
  userManageService,
  blockchainService,
} from "../../_services";

import "./styles.scss";
import withRouter from "../../_helpers/withRouter";
import { Autocomplete } from "@material-ui/lab";
import PropertyTable from "./PropertyTable";

const ManagePropertyPage = (props) => {
  const {
    alert,
    general,
    getAllPropertyActiveLocations,
    getAllProperties,
    getPropertyPricing,
    updatePropertyValues,
    deletePropertyById,
    getPurchasePendingProperties,
    clearAlerts,
    location,
    alertError,
  } = props;

  const [transferModalStatus, setTransferModalStatus] = useState(false);
  const [promoteModalStatus, setPromoteModalStatus] = useState(false);
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [immoTokenBalance, setImmoTokenBalance] = useState(undefined);
  const [zbsTokenBalance, setZbsTokenBalance] = useState(undefined);
  const [propertyPricing, setPropertyPricing] = useState({});
  const [modalAction] = useState("promote");
  const [properties, setProperties] = useState([]);
  const [showPending, setShowPending] = useState(false);
  const [users, setUsers] = useState({ owner: {}, buyer: {} });
  const [paymentRefNumber, setPaymentRefNumber] = useState("");
  const [transferPropId, setTransferPropId] = useState(0);

  const [page, setPage] = useState({
    size: 10,
    page: 0,
    count: 0,
  });

  // Add partner_id field to search
  const [search, setSearch] = useState({
    addressCountries: [],
    addressCities: [],
    propertyTitle: [],
    promoted: false,
    partner_id: null,
  });

  const [availableCities, setAvailableCities] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [partners, setPartners] = useState([]); // store fetched partners
  const [titleInput, setTitleInput] = useState("");

  const parseURLParams = useCallback((searchParams) => {
    const parsedSearch = { promoted: false };
    for (const [key, value] of searchParams.entries()) {
      try {
        const val = JSON.parse(value);
        if (key === "promoted") {
          parsedSearch.promoted = val === true;
        } else if (key === "partner_id") {
          parsedSearch.partner_id = val;
        } else {
          parsedSearch[key] = Array.isArray(val) ? val : [val];
        }
      } catch (e) {
        // if not json
        if (key === "partner_id") {
          parsedSearch.partner_id = value;
        } else {
          parsedSearch[key] = value ? [value] : [];
        }
      }
    }

    return {
      addressCountries: parsedSearch.addressCountries || [],
      addressCities: parsedSearch.addressCities || [],
      propertyTitle: parsedSearch.propertyTitle || [],
      promoted: parsedSearch.promoted,
      partner_id: parsedSearch.partner_id,
    };
  }, []);

  const updateURL = useCallback(() => {
    const newParams = new URLSearchParams();

    if (page.page > 0) newParams.set("page", page.page);
    if (page.size !== 10) newParams.set("limit", page.size);

    if (search.addressCountries.length > 0) {
      newParams.set(
        "addressCountries",
        JSON.stringify(search.addressCountries)
      );
    }
    if (search.addressCities.length > 0) {
      newParams.set("addressCities", JSON.stringify(search.addressCities));
    }
    if (search.propertyTitle.length > 0) {
      newParams.set("propertyTitle", JSON.stringify(search.propertyTitle));
    }
    if (search.promoted) {
      newParams.set("promoted", JSON.stringify(true));
    }
    if (search.partner_id) {
      newParams.set("partner_id", JSON.stringify(search.partner_id));
    }

    const paramString = newParams.toString();
    const newURL = paramString
      ? `${window.location.pathname}?${paramString}`
      : window.location.pathname;
    window.history.pushState({}, "", newURL);
  }, [search, page]);

  const loadProperties = useCallback(() => {
    clearAlerts();
    const filter = {
      addressCountries: search.addressCountries,
      addressCities: search.addressCities,
      propertyTitle: search.propertyTitle,
      promoted: search.promoted,
      partner_id: search.partner_id || null,
    };
    getAllProperties(page.page, page.size, undefined, filter);
    updateURL();
  }, [page, search]);

  const fetchBalances = useCallback(async () => {
    const balanceObj = await blockchainService.getBalances();
    setImmoTokenBalance(balanceObj.immo);
    setZbsTokenBalance(balanceObj.zbs);
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage((prev) => ({ ...prev, page: newPage }));
  };

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPage({ size: newSize, page: 0, count: page.count });
  };

  const handleSearchChange = (name, value) => {
    setSearch((prev) => {
      let updatedSearch = { ...prev };
      if (name === "addressCountry") {
        const countryVal = value ? [value] : [];
        updatedSearch.addressCountries = countryVal;
        updatedSearch.addressCities = []; // reset cities
        setPage((prevPage) => ({ ...prevPage, page: 0, count: 0 }));
      } else if (name === "addressCity") {
        if (value && prev.addressCountries.length > 0) {
          const country = prev.addressCountries[0];
          const cityFormatted = `${value} (${country})`;
          updatedSearch.addressCities = [cityFormatted];
        } else {
          updatedSearch.addressCities = [];
        }
        setPage((prevPage) => ({ ...prevPage, page: 0, count: 0 }));
      } else if (name === "propertyTitle") {
        updatedSearch.propertyTitle = value ? [value] : [];
      } else if (name === "partner_id") {
        updatedSearch.partner_id = value || null;
        setPage((prevPage) => ({ ...prevPage, page: 0, count: 0 }));
      }

      return updatedSearch;
    });
  };

  const resetFilter = () => {
    setSearch({
      addressCountries: [],
      addressCities: [],
      propertyTitle: [],
      promoted: false,
      partner_id: null,
    });
    setShowPending(false);
    setTitleInput("");
    setPage({ size: 10, page: 0, count: 0 });
    window.history.pushState({}, "", window.location.pathname);
    loadProperties();
  };

  const calculateRemainingPromotionDays = (startDate, durationDays = 30) => {
    const start = parseISO(startDate);
    const end = addDays(start, durationDays);
    const now = new Date();
    const remainingDays = differenceInDays(end, now);
    return remainingDays > 0 ? remainingDays : 0;
  };

  const handlePropertyPromote = async () => {
    setPromoteModalStatus(false);
    const res = await blockchainService.spendTokens(propertyPricing.amount);
    const data = {
      property_id: selectedProperty.property_id,
      is_promoted: 1,
      promoted_transaction_id: res.id,
      promoted_date: new Date(res.timestamp),
    };
    await updatePropertyValues(data);
    loadProperties();
  };

  const handlePropertyDemote = async (propertyId) => {
    const data = {
      property_id: propertyId,
      is_promoted: 0,
      promoted_transaction_id: null,
      promoted_date: null,
    };
    await updatePropertyValues(data);
    loadProperties();
  };

  const openPromoteModal = async (propertyId) => {
    const balanceObj = await blockchainService.getBalances();
    const prop = properties.find((p) => p.property_id === propertyId);
    setSelectedProperty(prop);
    setImmoTokenBalance(balanceObj.immo);
    setZbsTokenBalance(balanceObj.zbs);
    setPromoteModalStatus(true);
  };

  const closePromoteModal = () => {
    setPromoteModalStatus(false);
  };

  const openDeleteModal = (propertyId) => {
    const prop = properties.find((p) => p.property_id === propertyId);
    setSelectedProperty(prop);
    setDeleteModalStatus(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalStatus(false);
  };

  const handlePropertyRemove = () => {
    deletePropertyById(selectedProperty.property_id).then(() => {
      closeDeleteModal();
      loadProperties();
      setTimeout(() => clearAlerts(), 2000);
    });
  };

  const handlePropertyStatus = (id) => {
    const property = properties.find((p) => p.property_id === id);
    const data = {
      property_id: id,
      status: property.status === "active" ? "inactive" : "active",
    };
    updatePropertyValues(data).then(() => {
      loadProperties();
    });
  };

  const handlePropertySold = async (id) => {
    const property = properties.find((p) => p.property_id === id);
    const data = {
      property_id: id,
      is_sold: !property.is_sold,
    };
    await updatePropertyValues(data);
    loadProperties();
  };

  const getUser = async (id) => {
    let { data } = await userManageService.getUser(id);
    return data;
  };

  const openTransferModal = async (property) => {
    const {
      _user_id: ownerId,
      _buyer_id: buyerId,
      property_id: transferPropId,
      purchase_ref_number: refNumber,
    } = property;
    if (ownerId && buyerId && refNumber) {
      const owner = await getUser(ownerId);
      const buyer = await getUser(buyerId);

      setPaymentRefNumber(refNumber);
      setTransferPropId(transferPropId);
      setUsers({ owner, buyer });
      setTransferModalStatus(true);
    }
  };

  const closeTransferModal = () => {
    setPaymentRefNumber("");
    setTransferModalStatus(false);
    setUsers({ owner: {}, buyer: {} });
    loadProperties();
  };

  const handleFinishTransferModal = async () => {
    await generalService.transferProperty({
      property_id: transferPropId,
      _user_id: users.buyer.user_id,
      _buyer_id: null,
      purchase_ref_number: null,
      status: "inactive",
      is_promoted: 0,
      promoted_transaction_id: null,
      promoted_date: null,
    });
    closeTransferModal();
  };

  const handleRejectTransferModal = async () => {
    await generalService.transferProperty({
      property_id: transferPropId,
      _buyer_id: null,
      purchase_ref_number: null,
      status: "active",
    });
    closeTransferModal();
  };

  const handlePendingCheckboxChange = (e) => {
    if (e.target.checked) {
      getPurchasePendingProperties();
    } else {
      loadProperties();
    }
    setShowPending(e.target.checked);
  };

  const handlePromotedCheckboxChange = (e) => {
    const checked = e.target.checked;
    setSearch((prev) => ({ ...prev, promoted: checked }));
    setPage({ ...page, page: 0, count: 0 });
  };

  const getPropertyRowClass = (status, draft = false) => {
    if (draft) return "draft-property";
    let className = "";
    switch (status) {
      case "inactive":
        className = "inactive-property";
        break;
      case "pending-escrow":
        className = "pending-escrow-property";
        break;
      case "pending-utrust":
        className = "pending-utrust-property";
        break;
      default:
        break;
    }
    return className;
  };

  const isNumber = (num) => {
    return !isNaN(String(num));
  };

  // Fetch partners on mount
  useEffect(() => {
    // Use existing partner fetching logic
    // Assume showAll = true, no paging needed or just fetch first page with large limit
    generalService.getPartner(0, 50, true, "").then((data) => {
      if (data.status) {
        setPartners(data.data || []);
      } else {
        if (data.message) {
          alertError(data.message.toString());
        } else {
          alertError("Something went wrong");
        }
      }
    });
  }, [alertError]);

  // On initial mount, parse the URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newSearch = parseURLParams(searchParams);

    setSearch((prev) => ({
      ...prev,
      addressCountries: newSearch.addressCountries,
      addressCities: newSearch.addressCities,
      propertyTitle: newSearch.propertyTitle,
      promoted: newSearch.promoted,
      partner_id: newSearch.partner_id || null,
    }));

    // If page or limit were in the URL, parse them
    const newPage = searchParams.get("page");
    const newLimit = searchParams.get("limit");
    setPage((prev) => ({
      ...prev,
      page: newPage ? parseInt(newPage, 10) : 0,
      size: newLimit ? parseInt(newLimit, 10) : 10,
    }));
  }, []);

  // Listen to changes in location (popstate) and re-parse URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newSearch = parseURLParams(searchParams);

    setSearch((prev) => ({
      ...prev,
      addressCountries: newSearch.addressCountries,
      addressCities: newSearch.addressCities,
      propertyTitle: newSearch.propertyTitle,
      promoted: newSearch.promoted,
      partner_id: newSearch.partner_id,
    }));

    const newPage = searchParams.get("page");
    const newLimit = searchParams.get("limit");
    setPage((prev) => ({
      ...prev,
      page: newPage ? parseInt(newPage, 10) : 0,
      size: newLimit ? parseInt(newLimit, 10) : 10,
    }));
    // This effect fires whenever location.search changes,
    // ensuring that if user clicks back, we re-parse URL and load properties.
  }, [location.search, parseURLParams]);

  // After initial load, fetch balances and property pricing
  useEffect(() => {
    (async () => {
      await fetchBalances();
      getPropertyPricing(modalAction);
      getAllPropertyActiveLocations(true);
    })();
  }, []);

  // Trigger load properties whenever filters or pagination changes
  useEffect(() => {
    loadProperties();
  }, [
    search.addressCountries,
    search.addressCities,
    search.propertyTitle,
    search.promoted,
    search.partner_id,
    page.page,
    page.size,
  ]);

  // Update properties and pagination when general properties change
  useEffect(() => {
    const { dashboard_properties: propData, propertyPricing: pricingData } =
      general;
    if (
      pricingData &&
      pricingData.pricing &&
      pricingData.pricing.amount !== propertyPricing.amount
    ) {
      setPropertyPricing({ ...pricingData.pricing });
    }

    if (propData && propData.status === true) {
      setProperties(propData.data);
      setPage((prev) => ({
        ...prev,
        count:
          (propData.pagination && propData.pagination.count) ||
          propData.total ||
          0,
      }));
    } else if (propData && !propData.status) {
      setProperties([]);
      setPage((prev) => ({ ...prev, count: 0 }));
    }
  }, [general.dashboard_properties, general.propertyPricing]);

  // Update available countries once activePropertyLocations are fetched
  useEffect(() => {
    const activeLoc = general.activePropertyLocations || {};
    const countries = Object.keys(activeLoc).sort();
    setAvailableCountries(countries);
  }, [general.activePropertyLocations]);

  // Update cities based on selected country
  useEffect(() => {
    if (search.addressCountries.length > 0) {
      const country = search.addressCountries[0];
      const cities = general.activePropertyLocations[country] || [];
      setAvailableCities(cities);
    } else {
      setAvailableCities([]);
    }
  }, [search.addressCountries, general.activePropertyLocations]);

  useEffect(() => {
    if (titleInput) {
      const delay = 250; // debounce delay in ms
      const handler = setTimeout(() => {
        // Once the debounce delay has passed without a change,
        // update the search state
        handleSearchChange("propertyTitle", titleInput);
      }, delay);

      // If titleInput changes again before the delay is up,
      // clear the previous timeout and set a new one.
      return () => {
        clearTimeout(handler);
      };
    }
  }, [titleInput]);

  return (
    <div className="main-content manage-property-page">
      <DasboardNavBarComponent pageName="manage-property" />
      <Card className="new-grid-box">
        <Box mb={3}>
          <Link
            to={
              isNumber(immoTokenBalance) && immoTokenBalance >= 20
                ? "/dashboard/properties/add"
                : ""
            }
          >
            <Button
              variant="contained"
              color="primary"
              disabled={!isNumber(immoTokenBalance) || immoTokenBalance < 20}
            >
              Upload Property
            </Button>
          </Link>
          {isNumber(immoTokenBalance) ? (
            <Box sx={{ mt: 1, ml: 1, color: "#949292" }}>
              Note: You will need 20 IMB tokens to upload property
            </Box>
          ) : (
            <Box sx={{ mt: 1, ml: 1 }}>
              <Typography variant="body2" className="error">
                *Your token wallet is not linked properly!
              </Typography>
              <Typography variant="body2" className="error">
                *Try to unlink and import it again in tab{" "}
                <Link to="/dashboard/tokenwallet">Token Wallet</Link>
              </Typography>
            </Box>
          )}
        </Box>
        <Box ml={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPending}
                onChange={handlePendingCheckboxChange}
                name="showPending"
              />
            }
            label="Show purchase pending properties"
          />
        </Box>
        <Box mb={1} ml={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={search.promoted}
                onChange={handlePromotedCheckboxChange}
                name="showPromoted"
              />
            }
            label="Show only promoted properties"
          />
        </Box>
      </Card>

      {/* Filter Section */}
      <Card className="new-grid-box">
        <CardContent>
          <Box className="manage-property-filters">
            <Box className="manage-property-filter">
              <Typography
                style={{
                  display: "block",
                  padding: 0,
                  marginBottom: "5px",
                  fontWeight: 800,
                }}
              >
                Address Country
              </Typography>
              <Select
                className="form-control"
                value={search.addressCountries[0] || ""}
                onChange={(e) =>
                  handleSearchChange("addressCountry", e.target.value)
                }
                displayEmpty
                // disableUnderline
                MenuProps={{
                  getContentAnchorEl: () => null,
                }}
              >
                <MenuItem value="">Not selected</MenuItem>
                {availableCountries.map((country, index) => (
                  <MenuItem key={index} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="manage-property-filter">
              <Typography
                style={{
                  display: "block",
                  padding: 0,
                  marginBottom: "5px",
                  fontWeight: 800,
                  ...(!availableCities.length && { color: "#b0b0b0" }),
                }}
              >
                Address City
              </Typography>
              <Select
                className="form-control"
                value={
                  search.addressCities.length > 0
                    ? search.addressCities[0].split(" (")[0]
                    : ""
                }
                onChange={(e) =>
                  handleSearchChange("addressCity", e.target.value)
                }
                displayEmpty
                // disableUnderline
                disabled={!availableCities.length}
                MenuProps={{
                  getContentAnchorEl: () => null,
                }}
              >
                <MenuItem value="">
                  {availableCities.length
                    ? "Not selected"
                    : "No cities available"}
                </MenuItem>
                {availableCities.map((city, index) => (
                  <MenuItem key={index} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="manage-property-filter">
              <Typography
                style={{
                  display: "block",
                  padding: 0,
                  marginBottom: "5px",
                  fontWeight: 800,
                }}
              >
                Property Title
              </Typography>
              <TextField
                value={titleInput || ""}
                onChange={(e) => setTitleInput(e.target.value)}
                name="propertyTitle"
                placeholder="Type here..."
                className="form-control-title"
                // disableUnderline
                variant="outlined"
              />
            </Box>
            {/* Partner select field */}
            <Box className="manage-property-filter big-filter">
              <Typography style={{ fontWeight: 800, marginBottom: 5 }}>
                Partner
              </Typography>
              <Autocomplete
                fullWidth
                options={partners} // Pass the partners array as options
                getOptionLabel={(option) => option.name || ""} // Display partner name
                value={
                  partners.find(
                    (partner) => partner.id === search.partner_id
                  ) || null
                } // Set the selected value
                onChange={(event, newValue) => {
                  handleSearchChange(
                    "partner_id",
                    newValue ? newValue.id : null
                  ); // Update the selected value in state
                }}
                renderOption={(option) => (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ width: "100%" }}
                  >
                    <Grid item xs>
                      {option.name}
                    </Grid>
                  </Grid>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select a partner"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      style: { padding: 3, borderRadius: 5 }, // Remove padding
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box className="manage-property-filters">
            <Box className="manage-property-filter">
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={resetFilter}
              >
                Reset
              </Button>
            </Box>
            <Box className="manage-property-filter">
              <Button
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => {
                  setPage({ size: page.size, page: 0, count: 0 });
                  loadProperties();
                }}
              >
                Search
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {alert.message && (
        <NotificationBox
          open={true}
          variant={alert.type}
          message={alert.message}
        />
      )}

      {/* Render Properties */}
      {properties.length > 0 ? (
        <Card className="new-grid-box">
          <PropertyTable
            calculateRemainingPromotionDays={calculateRemainingPromotionDays}
            getPropertyRowClass={getPropertyRowClass}
            handlePageChange={handlePageChange}
            handlePropertyDemote={handlePropertyDemote}
            handlePropertySold={handlePropertySold}
            handlePropertyStatus={handlePropertyStatus}
            handleRowsPerPageChange={handleRowsPerPageChange}
            immoTokenBalance={immoTokenBalance}
            isNumber={isNumber}
            openDeleteModal={openDeleteModal}
            openPromoteModal={openPromoteModal}
            openTransferModal={openTransferModal}
            page={page}
            properties={properties}
            secureStorage={secureStorage}
            showPending={showPending}
          />
        </Card>
      ) : (
        <>
          <Divider /> <br />
          <Typography variant="h5">No properties found</Typography>
          <p>
            You can upload a property or buy one on{" "}
            <strong>
              <Link to="/marketplace">marketplace</Link>
            </strong>
          </p>
        </>
      )}

      {properties.length > 0 && (
        <Box sx={{ width: "100%", display: "flex" }}>
          <Box sx={{ my: 3, width: "100%", maxWidth: 550 }}>
            <Card className="new-grid-box">
              <CardContent>
                <Typography variant="h5">Table Legend</Typography>
                <Divider /> <Divider />
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem>
                    <Box
                      sx={{
                        py: 1,
                        px: 1.7,
                        mr: 2,
                        backgroundColor: "#f8e2c9",
                        borderRadius: 5,
                      }}
                    ></Box>
                    <ListItemText primary="Drafted property" />
                  </ListItem>
                  <ListItem>
                    <Box
                      sx={{
                        py: 1,
                        px: 1.7,
                        mr: 2,
                        backgroundColor: "#f2c9c7",
                        borderRadius: 5,
                      }}
                    ></Box>
                    <ListItemText primary="Inactive property" />
                  </ListItem>
                  <ListItem>
                    <Box
                      sx={{
                        py: 1,
                        px: 1.7,
                        mr: 2,
                        backgroundColor: "#cccddb",
                        borderRadius: 5,
                      }}
                    ></Box>
                    <ListItemText primary="Utrust payment pending" />
                  </ListItem>
                  <ListItem>
                    <Box
                      sx={{
                        py: 1,
                        px: 1.7,
                        mr: 2,
                        backgroundColor: "#ccdbdb",
                        borderRadius: 5,
                      }}
                    ></Box>
                    <ListItemText primary="Escrow payment pending" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}

      <DeleteModal
        open={deleteModalStatus}
        handleClose={closeDeleteModal}
        handleDelete={handlePropertyRemove}
        content={"property"}
      />
      <TransferPropertyModal
        handleClose={closeTransferModal}
        open={transferModalStatus}
        isOpen={transferModalStatus}
        owner={users.owner}
        buyer={users.buyer}
        paymentRefNum={paymentRefNumber}
        handleReject={handleRejectTransferModal}
        handleFinish={handleFinishTransferModal}
      />
      <TokenPayModal
        handleClose={closePromoteModal}
        open={promoteModalStatus}
        isOpen={promoteModalStatus}
        action={modalAction}
        propertyTitle={selectedProperty.title}
        content={`You are about to ${modalAction} this property`}
        immoTokenBalance={immoTokenBalance}
        zbsTokenBalance={zbsTokenBalance}
        payAmount={propertyPricing.amount}
        handleFinish={handlePropertyPromote}
      />
    </div>
  );
};

function mapState(state) {
  const { alert, general } = state;
  return { alert, general };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  getAllPropertyActiveLocations: generalActions.getAllPropertyActiveLocations,
  getAllProperties: generalActions.getAllProperties,
  getPropertyPricing: generalActions.getPropertyPricing,
  getPurchasePendingProperties: generalActions.getPurchasePendingProperties,
  updatePropertyValues: generalActions.updatePropertyValues,
  transferProperty: generalActions.transferProperty,
  deletePropertyById: generalActions.deletePropertyById,
};

const connectedPage = withRouter(
  connect(mapState, actionCreators)(ManagePropertyPage)
);
export { connectedPage as ManagePropertyPage };
