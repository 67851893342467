import React from "react";
import Counter from "./Counter";
import { useSelector } from "react-redux";

const CounterSection = () => {
  const { properties } = useSelector((state) => state?.general);
  return (
    <section className="counter-section">
      <div className="counter-row">
        <div className="counter-col">
          <p className="counter-title">
            Trust us to deliver <b>outstanding results</b> and make your{" "}
            <b>real estate journey</b> seamless.
          </p>
        </div>
        <div className="counter-col">
          <div className="single-counter-wrapper">
            <div className="single-counter">
              <Counter start={1} end={50} />
              <p>Countries</p>
            </div>
            <div className="single-counter">
              <Counter start={1} end={82} />
              <p>Agencies</p>
            </div>
          </div>
          <div className="single-counter-wrapper">
            <div className="single-counter">
              <Counter start={1} end={5740} />
              <p>Agents</p>
            </div>
            <div className="single-counter">
              <Counter start={1} end={properties?.total_marketplace_props} />
              <p>Properties</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;
