import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Box,
  TablePagination,
  Input,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import { generalActions, alertActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { generalService } from "../../_services";
import "./styles.scss";
import ManagePartnerTable from "./ManagePartnerTable";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

function ManagePartnerPage({
  alert,
  clearAlerts,
  alertError,
  deletePartnerById,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse initial URL parameters
  const parseURLParams = () => {
    const sp = new URLSearchParams(location.search);
    const parsedPage = parseInt(sp.get("page"), 10) || 0;
    const parsedLimit = parseInt(sp.get("limit"), 10) || 10;
    const parsedSearch = sp.get("search") || "";
    const parsedAll = sp.get("all") === "true";
    return { parsedPage, parsedLimit, parsedSearch, parsedAll };
  };

  const { parsedPage, parsedLimit, parsedSearch, parsedAll } = parseURLParams();

  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(parsedPage);
  const [limit, setLimit] = useState(parsedLimit);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState(parsedSearch);
  const [search, setSearch] = useState(parsedSearch);
  const [showAll, setShowAll] = useState(parsedAll);

  const [debounceTimer, setDebounceTimer] = useState(null);

  // Debounce search input
  useEffect(() => {
    if (debounceTimer) clearTimeout(debounceTimer);
    const timer = setTimeout(() => {
      const trimmed = searchText.trim();
      if (trimmed !== search) {
        setSearch(trimmed);
        setPage(0); // reset to first page when search changes
      }
      setDebounceTimer(null);
    }, 500);
    setDebounceTimer(timer);
  }, [searchText]);

  const updateURL = useCallback(() => {
    const params = new URLSearchParams();
    if (page > 0) params.set("page", page);
    if (limit !== 10) params.set("limit", limit);
    if (search) params.set("search", search);
    if (showAll) params.set("all", "true");
    const paramString = params.toString();
    const currentURL =
      window.location.pathname + (paramString ? `?${paramString}` : "");

    if (currentURL !== window.location.pathname + window.location.search) {
      navigate(currentURL, { replace: true });
    }
  }, [page, limit, search, showAll, navigate]);

  const loadPartners = useCallback(() => {
    clearAlerts();
    generalService.getPartner(page, limit, showAll, search).then((data) => {
      if (data.status) {
        setPartners(data.data);
        setCount(data.pagination ? data.pagination.count : 0);
      } else {
        if (data.message) {
          alertError(data.message.toString());
        } else {
          alertError("Something went wrong");
        }
      }
    });
  }, [page, limit, search, showAll, clearAlerts, alertError]);

  // Handle back/forward navigation or direct URL load
  useEffect(() => {
    const {
      parsedPage: newPage,
      parsedLimit: newLimit,
      parsedSearch: newSearch,
      parsedAll: newAll,
    } = parseURLParams();
    let changed = false;
    if (newPage !== page) {
      setPage(newPage);
      changed = true;
    }
    if (newLimit !== limit) {
      setLimit(newLimit);
      changed = true;
    }
    if (newSearch !== search) {
      setSearchText(newSearch);
      setSearch(newSearch);
      changed = true;
    }
    if (newAll !== showAll) {
      setShowAll(newAll);
      changed = true;
    }
    // If nothing changed, do nothing
  }, [location.search]);

  // Update URL and load data whenever page, limit, search, or showAll change
  useEffect(() => {
    updateURL();
    loadPartners();
  }, [page, limit, search, showAll, updateURL, loadPartners]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setLimit(newSize);
    setPage(0);
  };

  const handlePartnerRemove = (id) => {
    deletePartnerById(id).then(() => {
      loadPartners();
    });
  };

  return (
    <div className="main-content manage-partner-page">
      <DasboardNavBarComponent pageName="manage-partner" />

      <Card className="new-grid-box">
        <Box mb={3}>
          <Link to="/dashboard/partners/add">
            <Button startIcon={<AddCircleOutlineIcon />} size="large" variant="outlined" color="default" disableElevation>
              Add Partner
            </Button>
          </Link>
        </Box>

        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}

        <Box mb={2}>
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search by name (English)..."
            style={{
              fontSize: "16px",
              padding: "6px 14px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              width: "250px",
              backgroundColor: "#fff",
            }}
            disableUnderline
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={showAll}
                onChange={(e) => {
                  setShowAll(e.target.checked);
                  setPage(0);
                }}
                name="showAll"
              />
            }
            label="Show All"
          /> */}
        </Box>
          {/* <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Logo</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {partners.length > 0 ? (
                partners.map((partner, index) => (
                  <tr key={index}>
                    <td align="center">{page * limit + index + 1}</td>
                    <td align="center">{partner.name}</td>
                    <td align="center">
                      <img
                        alt="partner-logo"
                        src={partner.logo}
                        className="partner-logo"
                      />
                    </td>
                    <td align="center">
                      {ReactHtmlParser(partner.description)}
                    </td>
                    <td align="center">
                      <Link to={`/dashboard/partners/edit/${partner.id}`}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          style={{ marginRight: 5 }}
                        >
                          Edit
                        </Button>
                      </Link>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={() => handlePartnerRemove(partner.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} align="center" style={{ padding: "50px 0" }}>
                    No partners found
                  </td>
                </tr>
              )}
              <tr>
                <TablePagination
                  rowsPerPageOptions={[10, 50]}
                  labelRowsPerPage="Items per page:"
                  page={page}
                  count={count}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  onPageChange={handlePageChange}
                />
              </tr>
            </tbody>
          </table> */}
          <ManagePartnerTable
            partners={partners}
            page={page}
            limit={limit}
            count={count}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handlePageChange={handlePageChange}
            handlePartnerRemove={handlePartnerRemove}
          />
      </Card>
    </div>
  );
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  alertError: alertActions.error,
  deletePartnerById: generalActions.deletePartnerById,
};

const connectedPage = connect(mapState, actionCreators)(ManagePartnerPage);
export { connectedPage as ManagePartnerPage };
